import shared from "../../config/shared";
import AddressStore from "./store";
import { address as formatAddress } from "../../helpers/formatters";

const mapDefaults = () => {
  if (!google) {
    return {};
  }
  return {
    zoom: 12,
    maxZoom: 16,
    // init at brussels
    center: {
      lat: 50.8476,
      lng: 4.3572,
    },

    zoomControlOptions: {
      position: !!google && google?.maps?.ControlPosition?.RIGHT_BOTTOM,
    },
  };
};

/*function fillAddressPartsForm(address) {
  const formattedAddress = {
    city: null,
    postcode: null,
    street: null,
    building_number: null,
    ...address,
  };
  Object.keys(formattedAddress).forEach((inputName) => {
    $(`[name="${inputName}"`).val(formattedAddress[inputName]);
  });
}*/

function refreshActiveAddresses(container, template, addressStore) {
  if (!container || !template) {
    return;
  }
  const nodes = addressStore.items().map((item) => {
    let node = template.content.cloneNode(true);
    node.querySelector(".selected_address_string").innerText =
      item.address.formattedAddress;
    node
      .querySelector(".selected_address_btn")
      .addEventListener("click", () => {
        addressStore.deleteItem(item);
      });
    return node;
  });
  container.replaceChildren(...nodes);
}

const processAddressParts = (address_components) => {
  const keys = ["street", "postcode", "city"];
  const tmp = formatAddress(address_components);
  let parts = {};
  Object.keys(tmp)
    ?.filter((key) => keys.includes(key))
    ?.forEach((key) => {
      parts[key] = tmp[key];
    });

  const values = keys.map((k) => parts?.[k]);
  return {
    parts,
    formattedAddress: values.filter((i) => i !== null).join(" "),
  };
};
const getAdressFromPosition = async (position) => {
  const geocoder = new google.maps.Geocoder();
  const lat = position.lat();
  const lng = position.lng();
  const response = await geocoder.geocode({
    location: { lat, lng },
  });

  const results = response.results.filter((item) =>
    item.types.includes("street_address")
  );
  if (results[0]) {
    return processAddressParts(results[0].address_components);
  }
};
// ;

export default {
  when: () => {
    return $("body.search #map")?.length;
  },
  mounted() {
    // map container
    const elMap = document.getElementById("map");

    //active addresses container
    const selectedAddressesContainer = document.querySelector(
      ".selected_addresses"
    );
    // active address template
    const activeAddressesTemplate = document.querySelector(
      "template#selected_address"
    );

    // initialize the map
    const map = new google.maps.Map(elMap, mapDefaults());

    let addressStore = null;
    //callbacks for the address store
    const onUpdateItemsList = () => {
      refreshActiveAddresses(
        selectedAddressesContainer,
        activeAddressesTemplate,
        addressStore
      );
      //dispatch signal with updated addresses
      shared
        .get("dispatcher")
        .dispatch("change.addresslist", addressStore.items());
    };
    // initialize address store
    const onDeleteItem = (item) => {
      // delete the pin
      item?.pin.setMap(null);
      onUpdateItemsList();
    };
    const onAddItem = (item) => {
      const marker = new google.maps.Marker({
        map: map,
        position: item.location,
        draggable: true,
      });
      marker.addListener("dragend", async (e) => {
        const address = await getAdressFromPosition(e.latLng);
        addressStore.updateItem(item.id, { address, position: e.latLng });
      });
      addressStore.updateItem(item.id, { pin: marker });
    };

    // initialise address store
    addressStore = AddressStore(onAddItem, onDeleteItem, onUpdateItemsList);

    // add address when clicking on the map
    map.addListener("click", async (e) => {
      const address = await getAdressFromPosition(e.latLng);
      addressStore.addItem(address, e.latLng);
    });

    // when an autocompleted address is selected, add the address to the store
    shared.get("dispatcher").listen("change.js-autocomplete", (place) => {
      if (place) {
        addressStore.addItem(
          processAddressParts(place.address_components),
          place.geometry.location
        );
        // fill the addresss form
        //fillAddressPartsForm(item.address);
        onUpdateItemsList();
        //Clear out autocomplete field
        shared.get("dispatcher").dispatch("clear.js-autocomplete");
      }
    });
  },
};
