export default {
    when() {
        return $('.js-form').length > 0;
    },

    mounted() {
        $('.js-form').each(function () {
            const $this = $(this);
            const options = {
                ...$this.data(),
            };

            $this.find('input,textarea,select').one('blur keydown', function () {
                $(this).addClass('dirty');
            });

            $this.find('[type="submit"]').on('click', function () {
                // @see https://developer.mozilla.org/fr/docs/Web/API/HTMLFormElement/reportValidity
                if ($this[0].reportValidity()) {
                    const $btn = $(this);
                    $btn.addClass('loading');

                    setTimeout(() => {
                        $btn.removeClass('loading');
                    }, 5000);
                }
            });
        });
    },
};
