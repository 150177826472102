import SettingsMembersTable from "../../components/settings/SettingsMembersTable";

import { createRoot } from 'react-dom/client';

export default {

    when: 'settings-members',
    mounted:()=>{

        /**
         * Add SettingsMembersTable component
         * @type {Element}
         */
        const settingsMembersTableContainer = document.querySelector('#settings_members_table');
        const users = window.__users;
        const roles = window.__roles;
        createRoot(settingsMembersTableContainer).render(<SettingsMembersTable users={users} roles={roles} />);

    }
}
