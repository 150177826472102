import "datatables.net-select";
import { deletePlace } from "../controllers/PlacesController";
import template from "../helpers/template";
import { currency } from "../helpers/formatters";
import i18n from "../i18n";
import { createRoot } from "react-dom/client";
import PrintPlacesButton from "./PrintPlacesButton.jsx";

export const selectColumn = [
  {
    className: "select-checkbox",
    data(data, type, row) {
      return template(
        '<input class="placeCheckbox cursor-pointer checkbox form-tick appearance-none h-5 w-5 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none" id="checkbox-{{place_id}}" type="checkbox">',
        data
      );
    },
    name: "checkbox",
  },
];
export const defaultColumns = [
  {
    className: "px-2 py-4 whitespace-nowrap text-sm font-bold text-gray-900",
    data: (data) => {
      let res = data.address.street;
      if (data.address.building_number) {
        res += `, ${data.address.building_number}`;
      }
      return res;
    },
    render: (data, type, row, meta) => {
      const img = row.media?.[0]?.url
        ? `
                  <div class="h-10 w-10 inline-flex item-center rounded border border-grey-200 mr-2 | js-preview">
                     <img class="object-cover" src="${row.media[0].url}" alt="">
                  </div>
                `
        : "";

      return `
                <a class="link-hover" href="${row.links.detail.href}">
                    ${img}
                    ${data}
                </a>`;
    },
    name: "address",
    orderable: true,
  },
  { data: "address.postcode", name: "postcode", orderable: true },
  { data: "construction_year", name: "construction_year", orderable: true },
  {
    data: "building_type",
    render: (data, type, row, meta) => {
      if (data) {
        return i18n.t(`building.type.${data}`);
      }
      return "";
    },
    name: "building_type",
    orderable: true,
  },
  { data: "field_area", name: "field_area", orderable: true },
  {
    data: (data) => {
      const semPnd = Number(data.composition_sem_pnd);
      const totalSem = Number(data.composition_total_sem);
      const liveable = Number(data.liveable_area);
      if (semPnd && semPnd > 0) {
        return `${semPnd} (P)`;
      } else if (totalSem && totalSem > 0) {
        return `${totalSem} (U)`;
      } else if (liveable && liveable > 0) {
        return `${liveable} (H)`;
      }
      return null;
    },
    name: "surface",
  },
  { data: "address.city", name: "city", orderable: true },
  { data: "price", format: "currency", name: "price", orderable: true },
  {
    data: "act_date",
    render(data, type, row, meta) {
      if (data) {
        const date = new Date(data);
        return date.toLocaleDateString("fr");
      }
    },
    name: "act_date",
    orderable: true,
  },
];
export const actionColumn = [
  {
    data(data) {
      const templateString = document.querySelector(
        ".js-template-actions"
      ).innerHTML;
      return template(templateString, {
        delete_href: data.links.delete.href,
        detail_href: data.links.detail.href,
        edit_href: data.links.edit.href,
        id: data.id,
        starvariant: data.is_favorite ? "solid" : "regular",
      });
    },
    name: "action",
  },
];

export default class DataTableComponent {
  constructor(selector, options = {}, getAddressCallback) {
    this.el = selector;
    this.$el = $(selector);
    this.getAddressCallback = getAddressCallback;
    this.options = {
      endpoint: null,
      export: null,
      form: null,
      http: window.axios,
      // templateDefault: '.js-template-default',
      templateExpanded: ".js-template-expanded",
      ...options,
    };
    this.table = null;

    this.formData = new FormData();
    this.filtersData = null;
    this.initTable();
    this.initActions();
    this.initSearchForm();

    //force a load when we access the page  via somehting else than direct url
    if (performance.getEntriesByType("navigation")[0].type !== "navigate") {
      this.table.ajax.reload();
    }
  }

  /**
   * Initialize actions.
   */
  initActions() {
    const allowedActions = [
      "expand",
      "unexpand",
      "sort",
      "export",
      "favorite",
      "duplicate",
      "create",
    ];

    allowedActions.forEach((action) => {
      this.$el.find(`[data-action="${action}"]`).on("click", (e) => {
        e.preventDefault();
        e.stopPropagation();
        const $this = $(e.target);

        if (action === "sort") {
          this[action]($this.data("by"));
        } else {
          this[action]();
        }
      });
    });

    this.disableActions();
    //mount print button
    const printButtons = this.el.querySelectorAll(".js-print-places");
    const selector = this.el;
    printButtons.forEach((pb) => {
      createRoot(pb).render(<PrintPlacesButton tableEl={selector} />);
    });
  }

  /**
   * hook to an external search form
   */
  initSearchForm() {
    if (!this.options.form) {
      return;
    }

    const formEl = document.querySelector(this.options.form);
    const process = (e) => {
      //store the address form in a formdata object
      this.formData = new FormData(formEl);

      if (e.type === "filters_updated") {
        let filters_data = { ...e.detail };
        //move the residence field to the address formdata
        if (filters_data.residence) {
          this.formData.append("residence", filters_data.residence);
        }
        delete filters_data.residence;
        this.filtersData = { ...filters_data };
      }

      this.table.ajax.reload();
    };

    if (formEl) {
      this.formData = new FormData(formEl);
      formEl.addEventListener("submit", (e) => {
        process(e);
        e.preventDefault();
        return false;
      });

      // listen to event triggered on submit of the filter form
      document.addEventListener("filters_updated", (e) => {
        process(e);
        this.table.ajax.reload();
      });
    }
  }

  /**
   * Initialize DataTable.
   */
  initTable() {
    const tableOptions = {
      ajax: {
        data: (d) => {
          const addresses = this.getAddressFilterData();
          const orderBy = [];
          //compute orderby
          d.order?.forEach((statement) =>
            orderBy.push({
              name: d.columns[statement.column].name,
              dir: statement.dir,
            })
          );

          return {
            ...d,
            filters: { addresses, filters: { ...this.filtersData } },
            orderBy,
          };
        },
        type: "POST",
        url: this.options.endpoint,
      },
      drawCallback: (settings) => {
        const totalCount = settings?.json?.recordsTotal ?? 0;

        this.$el?.find(".js-count")?.text(totalCount);

        //initialize action dropdowns
        const delete_btns = document.querySelectorAll(
          'button[data-action="delete-place"]'
        );
        [...delete_btns].forEach((btn_el) => {
          btn_el.addEventListener("click", () => {
            this.onClickDelete(btn_el);
          });
        });
        $(document).trigger("refresh");
        //this is used for the create stepper, @TODO: rebase on the "refresh" event
        if (this.options.onRedrawCallback) {
          this.options.onRedrawCallback(settings?.json);
        }
      },
      info: false,
      initComplete: (settings) => {},
      language: {
        processing:
          '<div class="text-white absolute inset-0 z-50 flex justify-center items-center"><p>Chargement...</p></div>',
        paginate: {
          previous: "Précedent",
          next: "Suivant",
        },
        zeroRecords: "Aucun résultats ne correspond à vos critères",
        emptyTable: "Aucune données disponibles",
      },
      lengthChange: false,
      order: [[1, "asc"]],
      processing: true,
      responsive: true,
      searching: false,
      select: {
        selector: "td:first-child input",
        style: "multiple",
      },
      serverSide: true,
      stateSave: true,
      stateLoadParams: (settings, data) => {
        // prevent loading state from storage if using direct navigation
        if (performance.getEntriesByType("navigation")[0].type === "navigate") {
          return false;
        }
      },
      columns:
        this.options.tableOptions.select !== false
          ? [...selectColumn, ...defaultColumns, ...actionColumn]
          : [...defaultColumns, ...actionColumn],
      ...this.options.tableOptions,
    };

    // apply some default behaviors
    if (tableOptions.columns.length) {
      tableOptions.columns = tableOptions.columns.map((column) => {
        if (!column.className) {
          column.className =
            "px-3 py-4 whitespace-nowrap text-sm text-gray-500";
        }

        column.defaultContent = column.defaultContent ?? "";
        column.orderable = !!column.orderable;

        // @note - Allow to use custom formatter
        if (column.format) {
          const format = column.format;
          const availableFormats = {
            currency: (value) => currency(value),
          };
          column.data = (data) => {
            return availableFormats[format](data[column.name]);
          };
          delete column.format;
        }

        // @note - Allow to use custom template for a specific column
        const $template = this.$el.find(".js-template-" + column.name);
        if ($template.length) {
          column.data = (data) => {
            return template($template.html(), data);
          };
        }

        return column;
      });
    }

    this.table = this.$el.find(".js-table").DataTable({ ...tableOptions });

    this.table.on("deselect", (e, dt, type, indexes) => {
      const checkboxes = [...document.querySelectorAll("input.placeCheckbox")];
      indexes?.forEach((idx) => {
        if (checkboxes[idx]) {
          checkboxes[idx].checked = false;
        }
      });
    });
    // update counter value when select/deselect
    this.table.on("select deselect", () => {
      const count = this.table.rows({ selected: true }).count();
      this.$el.find(".js-counter").text(count > 0 ? `(${count})` : "");
      this.disableActions(!count);

      const selection =
        this.table.rows({ selected: true }).data().toArray() ?? [];

      const selectionUpdated = new CustomEvent("places-selection-updated", {
        detail: {
          ...selection,
        },
      });
      this.el.dispatchEvent(selectionUpdated);
    });
  }

  getAddressFilterData() {
    let addresses = [];
    let address = {};

    for (let pair of this.formData.entries()) {
      if (pair[0] === "_token" || pair[0] === "address") {
        continue;
      }

      if (pair[1]) {
        address[pair[0]] = pair[1];
      }
    }

    if (address && Object.keys(address).length) {
      addresses.push(address);
    }
    // get address callback is passed to the datatable component constructor to handle maps with multiple addresse selection
    if (this.getAddressCallback && this.getAddressCallback !== undefined) {
      addresses = [...addresses, ...this.getAddressCallback()];
    }

    return addresses;
  }

  /**
   * Enable/disable given actions.
   * @param disable
   * @param relatedActions
   */
  disableActions(
    disable = true,
    relatedActions = ["export", "favorite", "print", "duplicate"]
  ) {
    relatedActions.forEach((action) => {
      this.$el.find(`[data-action="${action}"]`).attr("disabled", disable);
    });
  }

  /**
   * Expand action.
   */
  expand() {
    this.$el.find('[data-action="expand"]').addClass("active");
    this.$el.find('[data-action="unexpand"]').removeClass("active");

    const instance = this;
    this.table?.rows().every(function () {
      const templateString = $(instance.options.templateExpanded).html();
      this.child(template(templateString, this.data())).show();
      $(this.node()).addClass("shown");
    });
  }

  /**
   * Export action.
   */
  export() {
    const selection = this.table.rows({ selected: true }).data() ?? [];
    const formData = new FormData();

    for (let i = 0, len = selection.length; i < len; i++) {
      const value = selection[i];
      formData.append(value.id, value.id);
    }

    const $handler = this.$el
      .find('[data-action="export"]')
      .addClass("loading");

    this.options
      .http({
        data: formData,
        method: "POST",
        responseType: "blob",
        url: this.options.export,
      })
      .then(
        (response) => {
          // dowload file
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const filename =
            response.headers["content-disposition"].split("filename=")?.[1] ??
            "export.xlsx";
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export.xlsx");
          document.body.appendChild(link);
          link.click();
          $handler.removeClass("loading");
          link.parentNode.removeChild(link);
        },
        (error) => {
          $handler.removeClass("loading");
          alert("Export failed!");
        }
      );
  }

  /**
   * Favorite action.
   */
  favorite() {
    console.log("-> @TODO - favorite");
  }

  onClickDelete(btn_el) {
    const endpoint = btn_el.dataset.endpoint;
    deletePlace(btn_el, () => {
      this.table.ajax.reload();
    });
  }

  /**
   * Sort action.
   * @param type
   */
  sort(type) {
    const types = {
      actDateAsc: [9, "asc"],
      actDateDesc: [9, "desc"],
      addressAsc: [1, "asc"],
      addressDesc: [1, "desc"],
      postcodeAsc: [2, "asc"],
      postcodeDesc: [2, "desc"],
      cityAsc: [7, "asc"],
      cityDesc: [7, "desc"],
    };
    this.table.order(types[type]).draw();
  }

  /**
   * Unexpand action.
   */
  unexpand() {
    this.$el.find('[data-action="expand"]').removeClass("active");
    this.$el.find('[data-action="unexpand"]').addClass("active");

    this.table?.rows().every(function () {
      this.child.hide();
      $(this.node()).removeClass("shown");
    });
  }
}
