import { createRoot } from "react-dom/client";
import InputSlider from "../components/InputSlider.jsx";
export default {
  when: true,

  mounted() {
    const sliders = document.querySelectorAll(".js-input-slider");
    sliders.forEach((slider) => {
      createRoot(slider).render(<InputSlider {...slider.dataset} />);
    });
  },
};
