import shared from "../../config/shared";
import DataTableComponent from "../../components/DataTableComponent";
import Stepper from "../../components/Stepper";
import { address } from "../../helpers/formatters";

class CreateDataTableComponent extends DataTableComponent {
  create() {
    const dispatcher = shared.get("dispatcher");
    dispatcher.dispatch("go-to-create-place");
  }

  duplicate() {
    const dispatcher = shared.get("dispatcher");
    const selection = this.table.rows({ selected: true }).data() ?? [];
    if (selection.length && selection[0]?.links?.duplicate?.href) {
      window.axios.post(selection[0].links.duplicate.href).then((response) => {
        window.location.href = `${window.location.href}/${response.data.place.id}`;
      });
    }
  }
}

//copy the address onto the createPlaceForm
const prefillNewPlaceForm = (searchFormEl, createFormEl) => {
  const source = {
    postcode: searchFormEl.querySelector("input[name='postcode']"),
    city: searchFormEl.querySelector("input[name='city']"),
    building_number: searchFormEl.querySelector(
      "input[name='building_number']"
    ),
    street: searchFormEl.querySelector("input[name='street']"),
  };

  const target = {
    postcode: createFormEl.querySelector("input[name='address[postcode]']"),
    city: createFormEl.querySelector("input[name='address[city]']"),
    building_number: createFormEl.querySelector(
      "input[name='address[building_number]']"
    ),
    street: createFormEl.querySelector("input[name='address[street]']"),
  };

  Object.entries(source).forEach((field) => {
    const source_value = field[1].value;
    const target_field = target?.[field[0]];
    if (target_field) {
      target_field.value = source_value;
      target_field.setAttribute("readonly", true);
    }
  });
};

export default {
  when: "create",

  mounted() {
    const dispatcher = shared.get("dispatcher");

    const searchform = document.getElementById("searchFormPlaces");
    const datatableEl = document.getElementById("createPlaceDatatable");
    const noResultsEl = document.getElementById("createPlaceNoResults");
    const createFormEl = document.getElementById("createplaceform");

    //activate the steppers
    const stepper = new Stepper();
    //stepper.goTo('step-search');

    //handle address form autocomplete
    shared.get("dispatcher").listen("change.js-autocomplete", (place) => {
      // fill form with place infos
      prefill(place.address_components);
    });

    dispatcher.listen("go-to-create-place", () => {
      prefillNewPlaceForm(searchform, createFormEl);
      //submit the form to create a place as draft
      createFormEl.submit();
    });

    function prefill(address_components) {
      const formattedAddress = address(address_components);
      Object.keys(formattedAddress).forEach((inputName) => {
        $(`[name="${inputName}"`).val(formattedAddress[inputName]);
      });
    }

    let freezeStep = true;
    //datatable callback
    let onRedraw = (data) => {
      if (!data?.hasOwnProperty("recordsFiltered") || freezeStep) {
        return;
      }
      if (data?.recordsFiltered > 0) {
        noResultsEl.classList.add("hidden");
        datatableEl.classList.remove("hidden");
      } else {
        noResultsEl.classList.remove("hidden");
        datatableEl.classList.add("hidden");
      }
      stepper.goTo("step-check");
    };

    //intercept the search form submit as it will be handled within the datatable
    if (searchform) {
      searchform.addEventListener("submit", (e) => {
        e.preventDefault();
        freezeStep = false;
        return false;
      });
    }

    // create button on createPlaceNoResult widget
    const createBtn = document.querySelector(
      "#createPlaceNoResults button[data-action=create]"
    );
    createBtn.addEventListener("click", () => {
      dispatcher.dispatch("go-to-create-place");
    });

    //mount the datatable

    $(".js-datatable").each(function () {
      const $this = $(this);
      $this.data(
        "instance",
        new CreateDataTableComponent(this, {
          ...$this.data(),
          onRedrawCallback: onRedraw,
          tableOptions: {
            deferLoading: 0, // do not load data automatically
            select: {
              style: "single",
              selector: "td:first-child input",
            },
          },
        })
      );
    });
  },
};
