class Stepper{
    constructor() {
        this.steps = document.querySelectorAll('[role=stepper-step]');
        this.currentStepId = this.steps[0].id;
    }

    goTo(targetId){
        //set current step as active
        const current = document.querySelector(`#${this.currentStepId}`);
        const currentStepperStep = document.querySelector(`[data-target=${this.currentStepId}]`);
        if(current){
            currentStepperStep.classList.remove('active')
            currentStepperStep.classList.add('valid')
            current.classList.add('hidden');
        }
        const target = document.querySelector(`#${targetId}`);
        const targetStepperStep = document.querySelector(`[data-target=${targetId}]`);
        target.classList.remove('hidden');
        targetStepperStep.classList.add('active')
        this.currentStepId=targetId;
    }
}
export default Stepper;
