export default {
    beforeMount() {
        $('.js-preview:not(.initialized)').each(function () {
            const $this = $(this).addClass('initialized relative overflow-hidden');
            const options = {
                previewClasses: 'hidden fixed z-10 w-auto max-h-80 shadow-lg transition-opacity delay-150 ease-in-out',
                ...$this.data(),
            };
            const $image = $this.find('img');

            const $preview = $('<img class="' + options.previewClasses + ' opacity-0" src="' + $image.attr('src') + '" alt="" />');
            


            $this.append($preview);

            $this.on('mouseenter', function () {
                $preview.css('top', $image.offset().top - $(window).scrollTop())
                $preview.css('left', $image.offset().left)
                $this.removeClass('overflow-hidden');
                $preview.removeClass(['opacity-0', 'hidden']);
                $preview.addClass('opacity-100');
            });

            // $this.on('mousemove', function (e) {
            //     const parentOffset = $this.offset();
            //     $preview.css({
            //         left: e.pageX - parentOffset.left,
            //         top: e.pageY - parentOffset.top,
            //     });
            // });

            $this.on('mouseleave', function () {
                $preview.removeClass('opacity-100');
                $preview.addClass(['opacity-0', 'hidden']);
                $this.addClass('overflow-hidden');
            });
        });
    },
    mounted() {
        $(document).on('refresh', () => this.beforeMount());
    },
};
