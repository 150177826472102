/**
 * Minimalist template engine
 *
 * @example
 * template('Hello {{thing}}!', { thing: 'world' });
 *
 * @param {String} template - The template string
 * @param {Array|Object} data - Data to use in template
 * @returns {String}
 */
export default function template(template, data) {
    data = flattenObject(data);
    for (const p in flattenObject(data)) {
        let value = '\u2014'
        if(data[p]){
            value = data[p]
        }
        template = template.replace(new RegExp('{{( )?'+ p +'( )?}}', 'g'), value);
    }
    //remove any unmatched keys
    template = template.replace(new RegExp('{{( )?.*( )?}}', 'g'), '');

    return template;
}

function flattenObject(data) {
    let result = {...data};

    for (const i in data) {
        if ((typeof data[i] === 'object') && !Array.isArray(data[i])) {
            const tmp = flattenObject(data[i]);

            for (const j in tmp) {
                result[ i + '.' + j ] = tmp[ j ];
            }
        } else {
            result[ i ] = data[ i ];
        }
    }

    return result;
}
