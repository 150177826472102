import React, { useState, useEffect } from "react";
import { Upload, Modal, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import i18next from "../i18n";
import axios from "axios";

const usePlace = ({ id }) => {
  const [place, setPlace] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(`/api/places/${id}`)
      .then((res) => {
        setPlace(res.data.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(setLoading(false));
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  return { place, error, loading };
};

const { Dragger } = Upload;

const UploadButton = ({ allowed }) => (
  <div className="flex items-center justify-center flex-col">
    <div className="mb-4">
      <svg
        width="39"
        height="38"
        viewBox="0 0 39 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.5 5H5.5C4.43913 5 3.42172 5.42143 2.67157 6.17157C1.92143 6.92172 1.5 7.93913 1.5 9V29M1.5 29V33C1.5 34.0609 1.92143 35.0783 2.67157 35.8284C3.42172 36.5786 4.43913 37 5.5 37H29.5C30.5609 37 31.5783 36.5786 32.3284 35.8284C33.0786 35.0783 33.5 34.0609 33.5 33V25M1.5 29L10.672 19.828C11.4221 19.0781 12.4393 18.6569 13.5 18.6569C14.5607 18.6569 15.5779 19.0781 16.328 19.828L21.5 25M33.5 17V25M33.5 25L30.328 21.828C29.5779 21.0781 28.5607 20.6569 27.5 20.6569C26.4393 20.6569 25.4221 21.0781 24.672 21.828L21.5 25M21.5 25L25.5 29M29.5 5H37.5M33.5 1V9M21.5 13H21.52"
          stroke="#9CA3AF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>

    <p className="text-sm font-medium text-gray-600 mb-2">
      {i18next.t("Click or drag an image to this area to upload")}
    </p>
    <p className="text-xs text-gray-500 font-regular">
      {i18next.t(":fileFormatList up to :fileLimit", {
        fileFormatList: allowed,
        fileLimit: "20Mb",
      })}
    </p>
  </div>
);

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default ({ csrfToken, uploadEndpoint, placeId, mode }) => {
  const { place, loading, error } = usePlace({ id: placeId });
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);

  const allowedFileTypes = ".png,.jpeg,.jpg,.gif,.pdf";

  useEffect(() => {
    const files = place?.media?.map((media) => ({
      uid: media.uuid,
      uuid: media.uuid,
      name: media.file_name,
      status: "done",
      url: media.url,
      thumbUrl: media.previewUrl,
    }));
    setFileList(files || []);
  }, [place, loading]);

  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = async (file) => {
    if (!file?.response?.url && !file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file?.response?.data?.url || file.url || file.preview),
      setPreviewVisible(true);
  };

  const handleRemove = (file) => {
    const uuid = file?.response?.data?.uuid || file?.uuid;
    if (uuid) {
      return axios.delete(`${uploadEndpoint}/${uuid}`);
    }
    return true;
  };
  const handleChange = ({ file, fileList }) => {
    setFileList(fileList);
  };
  return (
    <>
      <div className="clearfix">
        <Dragger
          name="picture"
          action={uploadEndpoint}
          fileList={[...fileList]}
          headers={{
            "X-CSRF-TOKEN": csrfToken,
          }}
          listType="picture-card"
          onPreview={handlePreview}
          onChange={handleChange}
          onRemove={handleRemove}
          showUploadList={{ showDownloadIcon: true, showRemoveIcon: true }}
          disabled={mode != "edit"}
        >
          {mode === "edit" && (
            <div>
              <UploadButton allowed={allowedFileTypes} />
            </div>
          )}
        </Dragger>
        <Image
          width={200}
          style={{ display: "none" }}
          src={previewImage}
          preview={{
            visible: previewVisible,
            src: previewImage,
            onVisibleChange: (value) => {
              setPreviewVisible(value);
            },
          }}
        />
      </div>
    </>
  );
};
