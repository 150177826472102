import React, { useState, useEffect } from "react";
import { Select, Spin, Table } from "antd";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import axios from "axios";

const useUgebRoles = () => {
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [fetched, setFetched] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (fetched) {
      return;
    }

    axios.get("/api/roles").then(({ data }) => {
      setRoles(
        data.data?.map((role) => ({
          value: role.id,
          name: t(`permissions.roles.${role.name}`),
        }))
      );
    });
    setFetched(true);
    setLoading(false);
  }, [fetched]);

  return {
    loading,
    roles,
  };
};

const usePlacesFieldsPermissions = () => {
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [fetched, setFetched] = useState(false);

  const { t } = useTranslation();
  useEffect(() => {
    if (fetched) {
      return;
    }

    axios.get("/api/places/permissions").then(({ data }) => {
      setFields(
        data.map((p) => ({
          name: p.name,
          label: t(`permissions.${p.name}`),
          id: p.id,
          role: p.role_id,
        }))
      );
    });
    setLoading(false);
    setFetched(true);
  }, [fetched]);

  return {
    loading,
    fields,
  };
};

const SelectRole = ({ defaultValue, roles, handleSelect }) => {
  return (
    <>
      <Select
        dropdownMatchSelectWidth={false}
        defaultValue={defaultValue}
        onSelect={handleSelect}
      >
        {roles.map((role) => (
          <Select.Option key={role.value} value={role.value}>
            {role.name}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};
SelectRole.propTypes = {
  defaultValue: PropTypes.number,
  roles: PropTypes.array,
  handleSelect: PropTypes.func,
};

const PermissionsTable = () => {
  const { t } = useTranslation();
  const { loading, fields } = usePlacesFieldsPermissions();
  const { roles } = useUgebRoles();
  const onChangeRole = (item, new_role_id) => {
    axios
      .post(`/api/places/permissions/${item.id}`, {
        role_id: new_role_id,
      })
      .then((response) => {
        console.log("permission updated successfully");
      })
      .catch((err) => {
        console.error("failed to change permission", err);
      });
  };

  const columns = [
    {
      title: t("field"),
      dataIndex: "label",
      key: "label",
    },
    {
      title: t("role"),
      key: "role",
      render: (item) => (
        <SelectRole
          defaultValue={item.role}
          roles={roles}
          handleSelect={(value) => {
            onChangeRole(item, value);
          }}
        />
      ),
    },
  ];
  return (
    <Spin spinning={loading}>
      <Table pagination={false} columns={columns} dataSource={fields} />
    </Spin>
  );
};

export default PermissionsTable;
