import { v4 as uuidv4 } from "uuid";

const AddressItem = (id, address, location, pin = null) => {
  return {
    id,
    location,
    address,
    pin,
  };
};
const AddressStore = (
  onAddItem = null,
  onDeleteItem = null,
  onUpdateItem = null
) => {
  let _items = [];

  const addItem = (address, location) => {
    const itemId = uuidv4();
    // add a marker at the position

    //fill in address form

    const item = AddressItem(itemId, address, location);
    _items.push(item);
    if (onAddItem) {
      onAddItem(item);
    }
    return item;
  };
  const updateItem = (itemId, data) => {
    _items = _items.map((i) => {
      if (i.id === itemId) {
        return {
          ...i,
          ...data,
        };
      }
      return i;
    });
    if (onUpdateItem) {
      onUpdateItem();
    }
  };

  const deleteItem = (item) => {
    _items = _items.filter((i) => item.id !== i.id);
    if (onDeleteItem) {
      onDeleteItem({ ...item });
    }
  };

  return {
    items: () => _items,
    addItem,
    deleteItem,
    updateItem,
  };
};

export default AddressStore;
