import axios from "axios";
import { createRoot } from "react-dom/client";
import GalleryUpload from "../components/GalleryUpload.jsx";
import ExportPlaceButton from "../components/ExportPlaceButton.jsx";
import DeleteConfirmationModale from "../components/DeleteConfirmationModale.jsx";
import { PrintSinglePlaceButton } from "../components/PrintPlacesButton.jsx";

export default {
  when() {
    return (
      document.querySelector("form#editplaceform") ||
      document.querySelector("body.editplace")
    );
  },
  mounted() {
    const form_el = document.querySelector("form#editplaceform");

    if (form_el) {
      handleEdit(form_el);
    }

    //mount gallery upload
    const galleryRootContainer = document.querySelector(
      ".js-places-upload-pictures"
    );
    const galleryRoot = createRoot(galleryRootContainer).render(
      <GalleryUpload {...galleryRootContainer.dataset} />
    );

    //mount export button
    const exportButtonRootContainer = document.querySelector(".js-export");
    if (exportButtonRootContainer) {
      const exportButtonRoot = createRoot(exportButtonRootContainer).render(
        <ExportPlaceButton {...exportButtonRootContainer.dataset} />
      );
    }
    //mount print button
    const printButtonRootContainer = document.querySelector(".js-print");
    if (printButtonRootContainer) {
      const printButtonRoot = createRoot(printButtonRootContainer).render(
        <PrintSinglePlaceButton {...printButtonRootContainer.dataset} />
      );
    }
  },
};

/**
 * Handle edit (and create) form.
 * @param form_el
 */
function handleEdit(form_el) {
  const original_data = new FormData(form_el);
  const save_btns = document.querySelectorAll("button.js-save-publish");

  const save_draft_btns = document.querySelectorAll("button.js-save-draft");
  save_draft_btns?.forEach((btn) => {
    btn.addEventListener("click", () => {
      const status_input = form_el.querySelector("input[name='status']");
      status_input.value = 0;
      form_el.submit();
    });
  });

  save_btns?.forEach((btn) => {
    btn.addEventListener("click", () => {
      const status_input = form_el.querySelector("input[name='status']");
      status_input.value = 1;
      form_el.submit();
    });
  });

  const cancel_btns = document.querySelectorAll("button.js-cancel");
  cancel_btns?.forEach((btn) => {
    btn.addEventListener("click", () => {
      form_el.reset();
    });
  });

  const delete_btns = document.querySelectorAll("button.js-delete");
  delete_btns?.forEach((btn) => {
    btn.addEventListener("click", () => {
      deletePlace(btn, () => {
        history.back();
      });
    });
  });

  // handle composition area form calculation
  const points = document.querySelectorAll(
    '[name="ground[Z1][pts]"], [name="ground[Z2][pts]"], [name="ground[Z3][pts]"]'
  );
  const totalArea = document.querySelector('[name="total_surface"]');
  const totalBef = document.querySelector('[name="total_bef"]');
  const totalPts = document.querySelector('[name="composition[total][pts]"]');
  if (points.length) {
    const groundDivider = document.querySelector('[name="ground_divider"]');
    points.forEach((point) => {
      const area = document.querySelector(
        '[name="' + point.name.replace("pts", "sup") + '"]'
      );
      const m2 = document.querySelector(
        '[name="' + point.name.replace("pts", "val_m2") + '"]'
      );

      [area, m2].forEach((element) => {
        element.addEventListener("input", () => {
          let value = +area.value * +m2.value;
          if (value === 0) {
            value = null;
          }
          point.value = Math.round(value);
          point.dispatchEvent(new Event("input"));
        });
      });
      point.addEventListener("input", () => {
        let value = Array.from(points).reduce((acc, pt) => acc + +pt.value, 0);
        if (value === 0) {
          value = null;
        }
        totalArea.value = Math.round(value);
        totalArea.dispatchEvent(new Event("input"));
      });

      // trigger onload
      [area, m2].forEach((element) => {
        element.dispatchEvent(new Event("input"));
      });
    });

    [totalArea, groundDivider].forEach((el) => {
      el.addEventListener("input", () => {
        let value = Number(
          ((+totalArea.value / +groundDivider.value) * 40.3399).toFixed(1)
        );
        if (value === 0) {
          value = null;
        }
        totalBef.value = Math.round(value);
        totalBef.dispatchEvent(new Event("input"));
      });
      el.dispatchEvent(new Event("input"));
    });
  }

  // handle composition structure form calculation
  const levels = document.querySelectorAll(
    '[name="composition[level1][pts]"], [name="composition[level2][pts]"], [name="composition[level3][pts]"], [name="composition[level4][pts]"], [name="composition[level5][pts]"], [name="composition[level6][pts]"], [name="composition[balcony][pts]"]'
  );

  const rooms = document.querySelectorAll(
    '[name="composition[kitchen][pts]"], [name="composition[sanitary][pts]"], [name="composition[heater][pts]"], [name="composition[other][pts]"]'
  );
  const totalSem = document.querySelector('[name="total_sem"]');
  const totalSemPnd = document.querySelector('[name="total_sem_pnd"]');
  const totalPtsRooms = document.querySelector('[name="total_area_structure"]');
  const allPts = [].concat(Array.from(levels), Array.from(rooms));

  if (levels.length) {
    allPts.forEach((pts) => {
      pts.addEventListener("input", () => {
        let value = allPts.reduce((acc, pt) => acc + Number(pt.value), 0);
        totalPts.value = value > 0 ? Math.round(value) : null;
        totalPts.dispatchEvent(new Event("input"));
      });
    });

    levels.forEach((level) => {
      const area = document.querySelector(
        '[name="' + level.name.replace("pts", "sem") + '"]'
      );
      const m2 = document.querySelector(
        '[name="' + level.name.replace("pts", "pts_m2") + '"]'
      );
      [area, m2].forEach((element) => {
        element.addEventListener("input", () => {
          let value = +area.value * +m2.value;
          level.value = value > 0 ? Math.round(value) : null;
          level.dispatchEvent(new Event("input"));

          const sem = Array.from(levels).map((l) =>
            Number(
              document.querySelector(
                '[name="' + l.name.replace("pts", "sem") + '"]'
              ).value
            )
          );
          const ptsM2 = Array.from(levels).map((l) =>
            Number(
              document.querySelector(
                '[name="' + l.name.replace("pts", "pts_m2") + '"]'
              ).value
            )
          );
          const maxPts = Math.max(...ptsM2);

          let res = 0.0;
          for (let i = 0; i < levels.length; i++) {
            res += (sem[i] / maxPts) * ptsM2[i];
          }
          totalSemPnd.value = res.toFixed(0);
          totalSemPnd.dispatchEvent(new Event("input"));
        });
      });

      level.addEventListener("input", () => {
        let value = Array.from(levels)
          .map((l) =>
            document.querySelector(
              '[name="' + l.name.replace("pts", "sem") + '"]'
            )
          )
          .reduce((acc, sem) => acc + +sem.value, 0);
        if (value === 0) {
          value = null;
        }
        totalSem.value = Math.round(value);
        totalSem.dispatchEvent(new Event("input"));
      });

      // trigger onload
      [area, m2].forEach((element) => {
        element.dispatchEvent(new Event("input"));
      });
    });
  }

  //handle total terrain + construction
  const totalTerrainConstruction = document.querySelector(
    "[name=total_area_structure]"
  );
  const totalTerrainConstructionEls = [totalPts, totalBef];
  totalTerrainConstructionEls.forEach((el) => {
    el.addEventListener("input", () => {
      totalTerrainConstruction.value = Math.round(totalTerrainConstructionEls.reduce(
        (acc, el) => acc + Number(el.value),
        0
      ));
      totalTerrainConstruction.dipatchEvent(new Event("input"));
    });
  });

  //bind the two price fields together
  const price_fields = document.querySelectorAll("[name=price]");
  [...price_fields]?.forEach((f) => {
    f.addEventListener("input", () => {
      let new_value = Number(f.value);
      [...price_fields].forEach(
        (el2) => (el2.value = new_value > 0 ? new_value : null)
      );
    });
  });

  //bind the two rcni fields together
  const rcni_fields = document.querySelectorAll("[name=rcni]");
  [...rcni_fields]?.forEach((f) => {
    f.addEventListener("input", () => {
      let new_value = Number(f.value);
      [...rcni_fields].forEach(
        (el2) => (el2.value = new_value > 0 ? new_value : null)
      );
    });
  });

  //calculate rapport % field
  const price_els = document.querySelectorAll("[name=price]");
  const rent_el = document.querySelector("[name=rent]");
  [...price_els, rent_el].forEach((f) => {
    f.addEventListener("input", () => {
      const price = [...price_els][0].value;
      const rent = rent_el.value;
      if (price && rent) {
        const rapport = (100 * Number(rent) * 12) / Number(price);
        [...document.querySelectorAll("[name=rapport]")].forEach(
          (el) => (el.value = rapport.toFixed(2))
        );
      }
    });
  });
  rent_el.dispatchEvent(new Event("input"));

  //calculate estimation totals
  const estimation_fields_els = document.querySelectorAll(
    '[name="additional_estimates[garage]"],[name="additional_estimates[manschdb]"],[name="additional_estimates[cellar]"],[name="additional_estimates[misc]"]'
  );
  const total_to_deduce_el = document.querySelector("[name=total_to_deduce]");
  const price_with_deduction_el = document.querySelector(
    "[name=price_with_deduction]"
  );

  //total_to_deduce
  [...estimation_fields_els].forEach((f) => {
    f.addEventListener("input", () => {
      const total = [...estimation_fields_els].reduce(
        (acc, el) => acc + Number(el.value),
        0
      );
      total_to_deduce_el.value = total;
      total_to_deduce_el.dispatchEvent(new Event("input"));
    });
  });

  //total_with_reduction
  [...price_els, total_to_deduce_el].forEach((f) => {
    f.addEventListener("input", () => {
      price_with_deduction_el.value =
        price_els[0].value - total_to_deduce_el.value;
      price_with_deduction_el.dispatchEvent(new Event("input"));
    });
  });

  //price/m2/pond and coeffs
  const price_m2_pnd_el = document.querySelector('[name="price_m2_pnd"]');
  const coeff_euro_el = document.querySelector('[name="coeff_euro"]');
  const coeff_bef_el = document.querySelector('[name="coeff_bef"]');
  [price_with_deduction_el, totalSemPnd, totalTerrainConstruction].forEach(
    (f) => {
      f.addEventListener("input", () => {
        const price_m2_pnd =
          Number(price_with_deduction_el.value) / Number(totalSemPnd.value);
        const coeff_euro =
          Number(price_with_deduction_el.value) /
          Number(totalTerrainConstruction.value);
        const coeff_bef = coeff_euro * 40.3399;
        price_m2_pnd_el.value = Math.round(price_m2_pnd);
        coeff_euro_el.value = Math.round(coeff_euro);
        coeff_bef_el.value = Math.round(coeff_bef);
      });
    }
  );
  [...estimation_fields_els][0].dispatchEvent(new Event("input"));

  // update hidden quota field when quota subfields are edited
  const quotas_fields = document.querySelectorAll('[name^="quotas_"]');
  const quotas_hidden_field = document.querySelector('[name^="quotas"]');
  quotas_fields?.forEach((f) => {
    f.addEventListener("input", () => {
      quotas_hidden_field.value = [...quotas_fields]
        .map((e) => e.value)
        .join("/");
      if (quotas_hidden_field.value === "/") {
        quotas_hidden_field.value = null;
      }
    });
  });
}

export const deletePlace = (el, cb, cancel_cb) => {
  const new_div = document.createElement("div");
  const props = {
    targetUrl: el.dataset.endpoint,
    title: el.dataset.title,
    contentText: el.dataset.contentText,
    cancelText: el.dataset.cancelText,
    okText: el.dataset.okText,
    onActionComplete: () => {
      cb();
    },
    onCancel: () => {
      console.log("user cancelled deletion");
    },
  };
  createRoot(new_div).render(<DeleteConfirmationModale {...props} />);
};
