import React,{useState, useEffect} from 'react'
import {
    InputNumber,
} from 'antd';


// takes a value un m2 and render 3 fields to reprenset the area in ha, a and ca (hecate, ares, centiares)
const InputArea = ({value, onChange}) => {
    const [splitValues, setSplitValues] = useState({ha:null, a:null, ca: null})
    const [m2Value, setm2Value] = useState(value)
    //computes the m2Value when haValue, aValue or caValue are updated


    //computes the haValue, aValue and caValue from m2Value when it's updated
    useEffect(()=>{
        if(m2Value!== null){
            const ha = Math.floor(m2Value/10000)
            const a = Math.floor((m2Value % 10000)/100)
            const ca = m2Value%100
            setSplitValues({ha, a, ca})
        }
    }, [])
    useEffect(()=>{
        if((splitValues.ha!=null || splitValues.a!=null || splitValues.ca!=null)){
            const sum = Math.floor(splitValues.ha*10000)+Math.floor(splitValues.a*100)+splitValues.ca
            if(!isNaN(sum)){
                onChange({value: Number(sum)})
            }
        }
    }, [splitValues])
    return (
        <div className="flex gap-4">
          <InputNumber
            step={1}
            min={0}
            value={splitValues.ha}
            onChange={(value)=>{setSplitValues({...splitValues, ha:value})}}
            addonAfter="ha"
          />
          <InputNumber
            step={1}
            min={0}
            value={splitValues.a}
            onChange={(value)=>{setSplitValues({...splitValues, a:value})}}
            addonAfter="a"
          />
          <InputNumber
            step={1}
            min={0}
            value={splitValues.ca}
            onChange={(value)=>{setSplitValues({...splitValues, ca:value})}}
            addonAfter="ca"
          />

        </div>
    )
}

export default InputArea
