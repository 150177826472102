import shared from '../config/shared';

/**
 * @example
 * <div class="js-toggle" data-name="foo"> Hello there! </div>
 * <button type="button" data-toggle="foo"> Toggle </button>
 */
export default {
    when() {
        return $('.js-toggle').length > 0;
    },

    mounted() {
        $('.js-toggle').each(function () {
            const $this = $(this);
            const options = {
                name: $this.data('name') || null,
                visible: $this.data('visible') || false,
                ...($this.data('options') || {}),
            };

            // allow to toggle element
            $(`[data-toggle="${options.name}"`).click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                toggle();
            });

            // allow to toggle element from dispatcher
            shared.get('dispatcher').listen('toggle.js-autocomplete', (target = '*') => {
                if (target === '*' || target === options.name) {
                    toggle();
                }
            });

            // allow to show element from dispatcher
            shared.get('dispatcher').listen('show.js-autocomplete', (target = '*') => {
                if (target === '*' || target === options.name) {
                    toggle(false);
                }
            });

            // allow to hide element from dispatcher
            shared.get('dispatcher').listen('hide.js-autocomplete', (target = '*') => {
                if (target === '*' || target === options.name) {
                    toggle(true);
                }
            });

            toggle(!options.visible);

            function toggle(hidden = null) {
                $this.toggleClass('hidden', hidden);
                options.visible = !$this.hasClass('hidden');

                // @note - we add an "active" class on trigger element when visible
                $(`[data-toggle="${options.name}"`).toggleClass('active', options.visible);
            }
        });
    },
}
