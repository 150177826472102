import React, { useState } from "react";
import i18next from "../../i18n";
import FilterForm from "./FilterForm";
import { Modal, Form } from "antd";

const ModalFooter = ({ onReset, onSubmit }) => (
  <div className="flex justify-between">
    <button className="text-indigo-600 bg-none border-none" onClick={onReset}>
      <i className="fal fa-trash mr-2" />
      <span>Reset</span>
    </button>
    <button className="btn btn-primary" onClick={onSubmit}>
      Apply
    </button>
  </div>
);

const defaultFilters = {
  transaction_types: [],
  price_min: null,
  price_max: null,
  act_date_min: null,
  act_date_max: null,
  building_types: [],
  residence: null,
  cadastre_division: null,
  cadastre_section: null,
  cadastre_numbers: null,
  rcni_min: null,
  rcni_max: null,
  field_area_min: null,
  field_area_max: null,
  construction_year_min: null,
  construction_year_max: null,
  bedroom_number_min: null,
  bedroom_number_max: null,
  garages_internal: null, //minimun
  garages_external: null, //minimum
  garden: null, //-1 = unspecified = null in db, 1=true, 0=false,  null means ignore filter
  user_id: null,
  comment: null,
};

const FilterPlaces = () => {
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);
  const handleSubmit = () => {
    const values = { ...form.getFieldsValue(true) };
    const filtersUpdatedEvent = new CustomEvent("filters_updated", {
      detail: values,
    });
    document.dispatchEvent(filtersUpdatedEvent);
    setIsVisible(false);
  };

  const handleReset = () => {
    form.resetFields();
  };

  const onCancel = () => {
    form.resetFields();
    setIsVisible(false);
  };

  const footer = <ModalFooter onReset={handleReset} onSubmit={handleSubmit} />;

  return (
    <>
      <button
        className="btn btn-light btn-rounded w-full text-center"
        id="filters-button"
        type="button"
        onClick={() => setIsVisible(true)}
      >
        <i className="fa-regular fa-sliders" aria-hidden="true"></i>
        {i18next.t("general.filters")}
      </button>
      <Modal
        title={i18next.t("filtersform.title")}
        visible={isVisible}
        footer={footer}
        style={{ maxHeight: "80vh" }}
        width="min(80vh, 755px)"
        className="overflow-hidden filter-modal"
        closable={true}
        onCancel={onCancel}
      >
        <FilterForm
          preserve={true}
          form={form}
          name="filters_form"
          layout="vertical"
          initialValues={{ ...defaultFilters }}
        />
      </Modal>
    </>
  );
};
export default FilterPlaces;
