import template from '../helpers/template'
/**
 * @example
 * <div class="js-toggle" data-name="foo"> Hello there! </div>
 * <button type="button" data-toggle="foo"> Toggle </button>
 */

const renderPost = (data) => {

}
const renderPosts = (tpl, posts) => {
    return posts.map(post => {
        const data = {
            title: post.title.rendered,
            content: post.acf.card.description,
            picture: post._embedded['wp:featuredmedia']?.[0].source_url,
            link: post.link
        }

        return template(tpl, data);
    })
}

const onStartLoad = (elsToHide)=>{
    elsToHide.forEach(el => {
        el.hide()
    })
}
const onLoadSuccess = (elsToShow) => {
    elsToShow.forEach(el => {
        el.show()
    })
}

const initSlider = (el) => {
    const sliderPrevious = el.querySelector('.js-slider-previous');
    const sliderNext = el.querySelector('.js-slider-next');
    const news = el.querySelector('.news-feed-container');
    const cardsWidth = el.querySelectorAll('.card-block')[0].offsetWidth;
    let scrolled = 0;
    const maxScroll = news.scrollWidth - news.clientWidth;

    sliderPrevious.onclick = slidePrevious;
    sliderNext.onclick = slideNext;

    function slidePrevious(){
        scrolled = scrolled - cardsWidth;
        if(scrolled < 0){
            scrolled = 0;
        }
        scroll();
    }
    function slideNext(){
        scrolled = scrolled + cardsWidth;
        if(scrolled > maxScroll){
            scrolled = maxScroll;
        }
        scroll();
    }
    function scroll(){
        if(scrolled == 0){
            sliderPrevious.classList.add("text-gray-300");
            sliderPrevious.classList.add("cursor-not-allowed");
            sliderPrevious.classList.remove("text-indigo-600");
            sliderPrevious.classList.remove("cursor-pointer");
        } else {
            sliderPrevious.classList.remove("text-gray-300");
            sliderPrevious.classList.remove("cursor-not-allowed");
            sliderPrevious.classList.add("text-indigo-600");
            sliderPrevious.classList.add("cursor-pointer");
        }
        if(scrolled == maxScroll){
            sliderNext.classList.add("text-gray-300");
            sliderNext.classList.add("cursor-not-allowed");
            sliderNext.classList.remove("text-indigo-600");
            sliderNext.classList.remove("cursor-pointer");
        } else {
            sliderNext.classList.remove("text-gray-300");
            sliderNext.classList.remove("cursor-not-allowed");
            sliderNext.classList.add("text-indigo-600");
            sliderNext.classList.add("cursor-pointer");
        }
        news.scroll({
            top: 0,
            left: scrolled,
            behavior: 'smooth'
        });
    }
    scroll();
}
export default {
    when() {
        return $('.js-news-feed').length > 0;
    },

    mounted() {
        $('.js-news-feed').each(function () {
            const $this = $(this);
            const feed_link = $this.data('endpoint');
            const tpl = $('#newsPostTemplate').html();
            const container=$(this).find('.news-feed-container')
            const slider=$(this).find('.news-feed-slider')
            onStartLoad([container, slider])
            fetch(feed_link, {
                mode: 'cors',
            })
                .then(response=>response.json())
                .then(data => {
                    renderPosts(tpl, data)?.forEach(p=>{
                        container.append(p)
                        onLoadSuccess([container, slider])
                        initSlider($(this)[0])
                    })
                })
        });
    },
}
