import { useState } from "react";

export const usePlace = (placeId) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState(null);
  !data &&
    window.axios
      .get(`/api/places/${placeId}`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => {
        setErrors(err);
      })
      .finally(() => {
        setLoading(false);
      });

  return { loading, data, errors };
};
