class OffCanvas {
  constructor() {
    this.sidebar = document.getElementById("sidebar-mobile");
    this.sidebarOverlay = document.getElementById("sidebar-mobile-overlay");
    this.sidebarMenu = document.getElementById("sidebar-mobile-menu");
    this.toggleButton = document.getElementById("sidebar-mobile-close");
    this.toggleButtonWrapper = document.getElementById(
      "sidebar-mobile-close-wrapper"
    );
    this.hamburgerButton = document.getElementById("hamburger-mobile");
    this.state = {
      open: false,
    };
    this.toggleButton.addEventListener("click", this.toggle);
    this.hamburgerButton.addEventListener("click", this.toggle);
  }

  toggle = (e) => {
    if (this.state.open) {
      this.close(e);
    } else {
      this.open(e);
    }
  };
  open(e) {
    this.state.open = true;
    this.sidebar.classList.replace("-translate-x-full", "translate-x-0");
    this.sidebarOverlay.classList.replace("opacity-0", "opacity-100");
    this.sidebarMenu.classList.replace("-translate-x-full", "translate-x-0");
    this.toggleButtonWrapper.classList.replace("opacity-0", "opacity-100");
  }

  close(e) {
    this.state.open = false;
    this.sidebar.classList.replace("translate-x-0", "-translate-x-full");
    this.sidebarOverlay.classList.replace("opacity-100", "opacity-0");
    this.sidebarMenu.classList.replace("translate-x-0", "-translate-x-full");
    this.toggleButtonWrapper.classList.replace("opacity-100", "opacity-0");
  }
}

export default {
  mounted() {
    const offcanvas = new OffCanvas();
  },
};
