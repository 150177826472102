import { createRoot } from 'react-dom/client';
import { Image } from 'antd';

export default {
    when() {
        return document.querySelectorAll('.js-image-preview')?.length > 0;
    },

  mounted() {
    [...document.querySelectorAll('.js-image-preview')].forEach(preview => {
      const props = {
        width: preview.dataset.width,
        src: preview.dataset.src,
        preview: {
          src: preview.dataset.previewSrc
        }
      }
      const root = createRoot(preview)
            .render(
                <Image {...props} />
            )
    })
  }
}
