import React, { useState, useEffect } from "react";
import i18next from "../i18n";
import { usePlace } from "./Place/hooks";
import { createPortal } from "react-dom";

const getSurface = (place) => {
  const semPnd = Number(place.composition_sem_pnd);
  const totalSem = Number(place.composition_total_sem);
  const liveable = Number(place.liveable_area);

  if (semPnd && semPnd > 0) {
    return `${semPnd} (P)`;
  } else if (totalSem && totalSem > 0) {
    return `${totalSem} (SU)`;
  } else if (liveable && liveable > 0) {
    return `${liveable} (H)`;
  }
  return null;
};

const getActDate = (place) => {
  if (place.act_date) {
    const date = new Date(place.act_date);
    if (!isNaN(date.getDate())) {
      return date.toLocaleDateString("fr-FR");
    }
  }
  return "";
};

const PrintablePlace = ({ place }) => {
  let mediaUrl = place?.media?.filter((m) => m?.previewUrl)?.[0]?.previewUrl;

  return (
    <div className="border border-black p-2">
      <div className="grid grid-cols-3 gap-3 w-full px-4 py-2">
        <div className="address col-span-2">
          {place?.lieudit ? (
            <p className="font-bold">
              {place?.address?.address2} (Lieu-Dit)
              <br />
              {place?.address.postcode} {place?.address.city}
            </p>
          ) : (
            <p className="font-bold">
              {place?.address?.street},{place?.address?.building_number} <br />
              {place?.address.postcode} {place?.address.city}
            </p>
          )}
        </div>
        <div className="col-span-1">
          {mediaUrl && <img src={mediaUrl} width="100%" height="auto" />}
        </div>
      </div>

      <div className="expanded-row summary">
        <div className="expanded-row__title">
          {i18next.t("places.sections.summary")}
        </div>
        <div className="expanded-row__content">
          <div className="item building_type">
            <span className="title">
              {i18next.t("places.datatable.headers.building_type.short")}:
            </span>
            <span className="value">{place.building_type_label}</span>
          </div>
          <div className="item field_area">
            <span className="title">
              {i18next.t("places.datatable.headers.field_area.short")}:
            </span>
            <span className="value">{place.field_area}</span>
          </div>
          <div className="item surface">
            <span className="title">
              {i18next.t("places.datatable.headers.surface.long")}:
            </span>
            <span className="value">{getSurface(place)}</span>
          </div>
          <div className="item price">
            <span className="title">
              {i18next.t("places.datatable.headers.price.long")}:
            </span>
            <span className="value">
              {place.price ? `${Number(place.price).toFixed(2)} €` : "-"}
            </span>
          </div>
          <div className="item act_date">
            <span className="title">
              {i18next.t("places.datatable.headers.act_date.long")}:
            </span>
            <span className="value">{getActDate(place)}</span>
          </div>
          <div className="item rcni">
            <span className="title">
              {i18next.t("places.fields.rcni_simple")}:
            </span>
            <span className="value">{place.rcni} €</span>
          </div>
          <div className="item transaction">
            <span className="title">
              {i18next.t("places.fields.transaction_type")}:
            </span>
            <span className="value">{place.transaction_type_label}</span>
          </div>
          <div className="item designation">
            <span className="title">
              {i18next.t("places.fields.designation")}:
            </span>
            <span className="value">{place.designation}</span>
          </div>
          <div className="item building_floor">
            <span className="title">
              {i18next.t("places.fields.building_floor")}:
            </span>
            <span className="value">{place.building_floor}</span>
          </div>
          <div className="item user">
            <span className="title">
              {i18next.t("places.fields.autfiche")}:
            </span>
            <span className="value">
              {place?.user?.first_name} {place?.user?.last_name}
            </span>
          </div>
          <div className="item rapport">
            <span className="title">{i18next.t("places.fields.rapport")}:</span>
            <span className="value">{place?.rapport}</span>
          </div>
        </div>
      </div>
      <div className="expanded-row description">
        <div className="expanded-row__title">
          {i18next.t("places.sections.description")}
        </div>
        <div className="expanded-row__content">
          <div className="item facade_number">
            <span className="title">
              {i18next.t("places.fields.facade_number")}:
            </span>
            <span className="value">{place?.facade_number}</span>
          </div>
          <div className="item floors_number">
            <span className="title">
              {i18next.t("places.fields.floors_number")}:
            </span>
            <span className="value">{place?.floors_number}</span>
          </div>
          <div className="item construction_year">
            <span className="title">
              {i18next.t("places.datatable.headers.construction_year.short")}:
            </span>
            <span className="value">{place?.construction_year}</span>
          </div>
          <div className="item room_number">
            <span className="title">
              {i18next.t("places.fields.room_number")}:
            </span>
            <span className="value">{place?.room_number}</span>
          </div>

          <div className="item parking_total">
            <span className="title">
              {i18next.t("places.fields.parking_total")}:
            </span>
            <span className="value">{place?.parking_total}</span>
          </div>
        </div>
      </div>
      <div className="expanded-row state">
        <div className="expanded-row__title">{i18next.t("Indices")}</div>
        <div className="expanded-row__content">
          <div className="item situation">
            <span className="title">
              {i18next.t("places.fields.situation")}:
            </span>
            <span className="value">{place?.situation}</span>
          </div>
          <div className="item construction">
            <span className="title">
              {i18next.t("places.fields.construction")}:
            </span>
            <span className="value">{place?.construction}</span>
          </div>
          <div className="item maintenance">
            <span className="title">
              {i18next.t("places.fields.maintenance")}:
            </span>
            <span className="value">{place?.maintenance}</span>
          </div>
        </div>
      </div>
      <div className="expanded-row peb">
        <div className="expanded-row__title">
          {i18next.t("places.sections.peb")}
        </div>
        <div className="expanded-row__content">
          <div className="item peb">
            <span className="title">{i18next.t("places.fields.peb")}:</span>
            <span className="value">{place?.peb}</span>
          </div>
          <div className="item peb_energy">
            <span className="title">{i18next.t("Consom.")}:</span>
            <span className="value">{place?.peb_energy}</span>
          </div>
        </div>
      </div>
      <div className="expanded-row details">
        <div className="expanded-row__title">{i18next.t("Détails")}</div>
        <div className="expanded-row__content">
          <div className="item construction_points">
            <span className="title">{i18next.t("Points constructions")}:</span>
            <span className="value">{place?.construction_points}</span>
          </div>
          <div className="item combined_points">
            <span className="title">{i18next.t("Total Points")}:</span>
            <span className="value">{place?.combined_total_points}</span>
          </div>
          <div className="item composition_sem_pnd">
            <span className="title">{i18next.t("Surface pondérée")}:</span>
            <span className="value">{place?.composition_sem_pnd}</span>
          </div>
        </div>
      </div>
      <div className="expanded-row financial">
        <div className="expanded-row__title">
          {i18next.t("places.sections.financial")}
        </div>
        <div className="expanded-row__content">
          <div className="item coeff_bef">
            <span className="title">
              {i18next.t("places.estimates.coeff_bef")}:
            </span>
            <span className="value">{place?.coeff_bef}</span>
          </div>
          <div className="item price_m2_pnd">
            <span className="title">
              {i18next.t("places.estimates.price_m2_pnd")}:
            </span>
            <span className="value">{place?.price_m2_pnd}</span>
          </div>
        </div>
      </div>
      <div className="expanded-row note">
        <div className="expanded-row__title">{i18next.t("Note")}</div>
        <div className="expanded-row__content">
          <div className="item comment">
            <span className="value">{place?.comment}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrintPlacesCollection = ({ places }) => {
  return createPortal(
    <>
      {places?.map((place) => (
        <div className="mb-4 break-inside-avoid	" key={place.id}>
          <PrintablePlace place={place} />
        </div>
      ))}
    </>,
    document.getElementById("printContainer")
  );
};
const PrintPlacesButton = ({ tableEl }) => {
  const [printables, setPrintables] = useState([]);

  useEffect(() => {
    document.getElementById("app")?.classList.add("print:hidden");
    tableEl?.addEventListener("places-selection-updated", (e) => {
      setPrintables([...Object.values(e.detail)]);
    });
    return () => {
      tableEl?.removeEventListener("places-selection-updated");
    };
  }, []);

  const triggerPrint = () => {
    window.print();
  };
  return (
    <>
      <button
        className={`btn btn-light btn-rounded ${
          printables.length < 1 ? "disabled" : ""
        }`}
        type="button"
        disabled={printables.length < 1}
        onClick={triggerPrint}
      >
        <i className="fa-regular fa-print" aria-hidden="true"></i>
        {i18next.t("general.print")}
        {printables.length > 0 && (
          <span className="ml-1">({printables.length})</span>
        )}
      </button>
      <PrintPlacesCollection places={printables} />
    </>
  );
};

export default PrintPlacesButton;

export const PrintSinglePlaceButton = ({ placeId }) => {
  const { loading, data, errors } = usePlace(placeId);
  return (
    <>
      <button
        className={`btn btn-light btn-rounded ${
          loading || errors || !data ? "disabled" : ""
        }`}
        onClick={() => {
          window.print();
        }}
      >
        <i className="fa-regular fa-print" aria-hidden="true"></i>
        {i18next.t("general.print")}
      </button>
      {!loading && data && <PrintPlacesCollection places={[data]} />}
    </>
  );
};
