import DataTableComponent, {selectColumn,defaultColumns, actionColumn} from '../components/DataTableComponent';
import template from '../helpers/template';
import i18n from '../i18n';

export default {
    when: "userplaces",

    mounted() {
        $('.js-datatable').each(function () {
            const $this = $(this);
            $this.data('instance', new DataTableComponent(this, {
                ...$this.data(),
                tableOptions: {
                    select:true,
                    stateSave: false,
                    paging: true,
                    info: false,
                    order: [
                        [10, 'asc']
                    ],
                    columns: [
                        ...selectColumn,
                        ...defaultColumns,
                        {
                            data: 'status',
                            render(data, type, row, meta) {
                                const status = data ?? 1;
                                return `<span class="status-pill status-${status}">${i18n.t(`places.status.${status}`)}</span>`
                            },
                            name: 'status',
                            orderable: true,
                        },
                        ...actionColumn
                    ],
                },
            }));
        });
    },
}
