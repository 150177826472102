import GMaps from 'gmaps';
import shared from '../config/shared';
import DataTableComponent from '../components/DataTableComponent'
import template from '../helpers/template'
export default {
    when: 'favorites',

    mounted() {
        //mount the datatable
        $('.js-datatable').each(function () {
            const $this = $(this);
            $this.data('instance', new DataTableComponent(
                this,
                {
                    ...$this.data(),
                    tableOptions:{}
                }
            ));
        });
    },
}
