import DataTableComponent from "../components/DataTableComponent";
import { createRoot } from "react-dom/client";
import FilterPlaces from "../components/FilterPlaces";
import shared from "../config/shared";

export default {
  when: "search",
  mounted() {
    let selectedAddresses = [];
    shared.get("dispatcher").listen("change.addresslist", (addresses) => {
      selectedAddresses = addresses;
    });

    const getAddresses = () => {
      const keys = ["building_number", "city", "postcode", "street"];
      return selectedAddresses.map((a) => {
        a = a.address.parts;
        let r = {};
        Object.keys(a)
          .filter((k) => keys.includes(k))
          .forEach((k) => {
            r[k] = a[k];
          });
        return r;
      });
    };
    //mount the datatable

    $(".js-datatable").each(function () {
      const $this = $(this);
      $this.data(
        "instance",
        new DataTableComponent(
          this,
          {
            ...$this.data(),
            tableOptions: {
              deferLoading: 0, // do not load data automatically
              order: [[9, "desc"]],
              pageLength: 50,
            },
          },
          getAddresses
        )
      );
      //mount the filters form

      const filters_modale_el = document.getElementById("search-filters-root");
      createRoot(filters_modale_el).render(<FilterPlaces />);
    });
  },
};
