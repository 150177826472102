import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const resources = {
  fr: {
    translation: window.translations
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "fr",
    interpolation: {
      prefix: '{{',
      suffix: '}}'
    }
  })

export default i18n
