let mounted = false;
export default {
    beforeMount() {
        $(document).on('refresh', () => this.mounted());
    },

    mounted() {
        const toggles = document.querySelectorAll('.toggleFavorite')
        toggles.forEach(t=>{
            t.addEventListener('click', (e)=>{
                e.preventDefault();
                fetch(t.dataset.endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body:JSON.stringify( {
                        placeIds: [t.dataset.placeid]
                    })

                })
                    .then(response=>response.json())
                    .then(data => {
                        const icon = t.querySelector('i')
                        if(data[t.dataset.placeid]){
                            console.log('activate toggle')
                            icon.classList.remove('fa-regular')
                            icon.classList.add('fa-solid')
                        }else{
                            icon.classList.add('fa-regular')
                            icon.classList.remove('fa-solid')
                        }
                    })
                return false;
            })
        })

    },
};
