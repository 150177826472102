import Controller from "./helpers/Controller";
import "./bootstrap";

// Components
// import CalculationComponent from './components/CalculationComponent';
import DropdownComponent from "./components/DropdownComponent";
import FavoriteToggleComponent from "./components/FavoriteToggleComponent";
import FormComponent from "./components/FormComponent";
import GoogleAutocompleteComponent from "./components/GoogleAutocompleteComponent";
import ModalComponent from "./components/ModalComponent";
import NewsFeed from "./components/NewsFeed";
import PreviewComponent from "./components/PreviewComponent";
import TabsComponent from "./components/TabsComponent";
import ToggleComponent from "./components/ToggleComponent";
import ThumbnailPreview from "./components/ThumbnailPreview";

// Controllers
import SearchController from "./controllers/SearchController";
import offcanvas from "./controllers/offcanvas";
import DashboardController from "./controllers/DashboardController";
import CreatePlaceController from "./controllers/places/create";
import FavoritesController from "./controllers/FavoriteController";
import UserPlacesController from "./controllers/UserPlacesController";
import PlacesController from "./controllers/PlacesController";
import settingsMembers from "./controllers/settings/members";
import settingsPermissions from "./controllers/settings/permissions";
import CommonController from "./controllers/CommonController";
import SearchMapController from "./controllers/searchMap";

const modules = [
  // Components
  // CalculationComponent,
  DropdownComponent,
  FavoriteToggleComponent,
  FormComponent,
  GoogleAutocompleteComponent,
  ModalComponent,
  NewsFeed,
  PreviewComponent,
  TabsComponent,
  ToggleComponent,
  ThumbnailPreview,

  // Controllers
  DashboardController,
  SearchController,
  offcanvas,
  settingsMembers,
  settingsPermissions,
  CreatePlaceController,
  FavoritesController,
  UserPlacesController,
  PlacesController,
  CommonController,
  SearchMapController,
];
const controller = new Controller(modules);
controller.ready();

// @note - this will popup a confirmation before leaving the page
// window.onbeforeunload = () => controller.destroy();
