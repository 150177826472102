export default class Dispatcher {
    /**
     * The registered event listeners.
     * @type {*}
     * @private
     */
    _listeners = new Map();

    /**
     * Fire an event and call the listeners.
     * @param event
     * @param payload
     * @param halt
     * @return {null|*[]}
     */
    dispatch(event, payload = {}, halt = false) {
        const listeners = this.getListeners(event);
        const responses = [];

        let continues = true;

        listeners.forEach((listener) => {
            if (continues) {
                const response = listener(payload);

                if (halt && response !== null) {
                    return response;
                }

                if (response === false) {
                    continues = false;
                } else {
                    responses.push(response);
                }
            }
        });

        return halt ? null : responses;
    }

    /**
     * An alias of dispatch method.
     * @param event
     * @param payload
     * @param halt
     * @return {*[]|null}
     */
    emit(event, payload = {}, halt = false) {
        return this.dispatch(event, payload, halt);
    }

    /**
     * Remove a set of listeners from the dispatcher.
     * @param event
     * @return {Dispatcher}
     */
    forget(event) {
        this._listeners.delete(event);

        return this;
    }

    /**
     * An alias of forget method.
     * @param event
     * @return {Dispatcher}
     */
    off(event) {
        return this.forget(event);
    }

    /**
     * Get all of the listeners for a given event name.
     * @param event
     * @return {*[]}
     */
    getListeners(event) {
        if (event.includes('*')) {
            return this.getWildcardListeners(event);
        }

        return this._listeners.get(event) || [];
    }

    /**
     * Get the wildcard listeners for the event.
     * @param event
     * @return {*[]}
     */
    getWildcardListeners(event) {
        let wildcards = [];

        const regex = new RegExp('^' + event.replace(/[-[\]{}()+?.,\\^$|#\s]/g, '\\$&').replace('*', '.+'), 's');

        this._listeners.forEach((listeners, key) => {
            if (key.match(regex)) {
                wildcards = [].concat(wildcards, listeners);
            }
        });

        return wildcards;
    }

    /**
     * Determine if a given event has listeners.
     * @param event
     * @return {boolean}
     */
    hasListeners(event) {
        const listeners = this._listeners.get(event) || [];

        return listeners.length > 0;
    }

    /**
     * Register an event listener with the dispatcher.
     * @param events
     * @param listener
     * @return {Dispatcher}
     */
    listen(events, listener) {
        if (!Array.isArray(events)) {
            events = events.split(' ');
        }

        events.forEach((event) => {
            const listeners = this._listeners.get(event) || [];

            listeners.push(this.makeListener(listener));

            this._listeners.set(event, listeners);
        });

        return this;
    }

    /**
     * An alias of listen method.
     * @param events
     * @param listener
     * @return {Dispatcher}
     */
    on(events, listener) {
        return this.listen(events, listener);
    }

    /**
     * Register an event listener with the dispatcher.
     * @param listener
     * @return {function(*=): *}
     */
    makeListener(listener) {
        return (payload) => {
            if (typeof listener !== 'function') {
                return;
            }

            return listener(payload);
        };
    }

    /**
     * Fire an event until the first non-null response is returned.
     * @param event
     * @param payload
     * @return {*[]|null}
     */
    until(event, payload = {}) {
        return this.dispatch(event, payload, true);
    }
}
