import PropTypes from "prop-types";
import React from "react";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

const SettingsMembersTable = ({ users, roles }) => {
  const { t } = useTranslation();

  const [editForm] = Form.useForm();
  const [newForm] = Form.useForm();

  const [loading, setLoading] = useState(true);

  const [current, setCurrent] = useState();

  const [create, setCreate] = useState();

  const [source, setSource] = useState([]);

  const columns = [
    {
      title: t("user.last_name"),
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => {
        return a.last_name < b.last_name ? -1 : 1 ?? 0;
      },
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
    },
    {
      title: t("user.first_name"),
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => {
        return a.first_name < b.first_name ? -1 : 1 ?? 0;
      },
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Role",
      key: "role",
      sorter: (a, b) => {
        return a.role < b.role ? -1 : 1 ?? 0;
      },
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],

      render: (item) => {
        let handleSelect = (val) => {
          setLoading(true);
          axios
            .put(`/api/users/${item.id}`, {
              role: val,
            })
            .finally(() => {
              setLoading(false);
              fetchData();
            });
        };

        return (
          <Select
            key={`select-role-${item.id}`}
            dropdownMatchSelectWidth={false}
            onSelect={handleSelect}
            value={item.role}
          >
            {roles?.map((role) => (
              <Select.Option key={role.name} value={role.name}>
                {t(`permissions.roles.${role.name}`)}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Actions",
      key: "id",
      render: (item) => {
        return (
          <>
            <Button.Group>
              <Popconfirm
                title={"Are you sure ?"}
                onConfirm={() => handleDelete(item)}
              >
                <Button>Delete</Button>
              </Popconfirm>
              <Button onClick={() => handleEdit(item)}>Edit</Button>
            </Button.Group>
          </>
        );
      },
    },
  ];

  // On mounted we get data from api
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get("/api/users")
      .then((res) => {
        setSource(
          res.data.data.map((item) => {
            return {
              key: item.id,
              ...item,
            };
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (item) => {
    setLoading(true);
    axios
      .delete(`/api/users/${item.id}`)
      .then(() => {
        message.success(t("Deleted"));
      })
      .catch((err) => {
        message.error(t("Cannot delete"));
      })
      .finally(() => {
        setLoading(false);
        fetchData();
      });
  };

  /**
   * Open the edit modal and set current user to modify
   *
   * @param item
   */
  const handleEdit = (item) => {
    setCurrent(item);
    setLoading(false);
    editForm.setFieldsValue({
      last_name: item.last_name,
      first_name: item.first_name,
      email: item.email,
      role: item.role,
    });
  };

  /**
   * Close edit modal and set current to null
   */
  const handleClose = () => {
    setCurrent(null);
    setCreate(null);
    setLoading(false);
    fetchData();
  };

  /**
   * Submit
   */
  const submitEdit = () => {
    let values = editForm.getFieldsValue();
    setLoading(true);
    axios
      .put(`/api/users/${current.id}`, values)
      .then(() => {
        message.success(t("Saved."));
      })
      .catch((err) => {
        message.error(t("Server Error"));
      })
      .finally(() => {
        setCurrent(null);
        fetchData();
      });
  };

  const submitCreate = () => {
    setLoading(true);
    let values = newForm.getFieldsValue();

    axios
      .post("/api/users", values)
      .then(() => {
        message.success(t("Saved."));
      })
      .catch((err) => {
        message.error(t("Server Error"));
      })
      .finally(() => {
        setCreate(false);
        fetchData();
      });
  };

  return (
    <Spin spinning={loading}>
      <Row className={"my-2"}>
        <Col className={"text-right"}>
          <Button onClick={() => setCreate(true)}>{t("Create")}</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        showSorterTooltip={false}
        dataSource={source}
        rowKey={(user) => user.id}
      />
      <Modal
        visible={current}
        title={t("Edit the user")}
        okText="Save"
        cancelText="Cancel"
        onCancel={handleClose}
        onOk={submitEdit}
      >
        {
          <Form form={editForm} layout={"vertical"}>
            <Form.Item name={"last_name"} label={t("user.last_name")}>
              <Input />
            </Form.Item>
            <Form.Item name={"first_name"} label={t("user.first_name")}>
              <Input />
            </Form.Item>
            <Form.Item name={"email"} label={t("user.email")}>
              <Input />
            </Form.Item>
            <Form.Item name={"role"} label={t("Role")}>
              <Select>
                {roles?.map((role) => (
                  <Select.Option key={role.name} value={role.id}>
                    {t(`permissions.roles.${role.name}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        }
      </Modal>
      <Modal
        visible={create}
        title={t("Create")}
        okText={t("Create")}
        cancelText={t("Cancel")}
        onCancel={handleClose}
        onOk={submitCreate}
      >
        {
          <Form form={newForm} layout={"vertical"}>
            <Form.Item name={"last_name"} label={t("user.last_name")}>
              <Input />
            </Form.Item>
            <Form.Item name={"first_name"} label={t("user.first_name")}>
              <Input />
            </Form.Item>
            <Form.Item name={"email"} label={t("user.email")}>
              <Input />
            </Form.Item>
            <Form.Item name={"role"} label={t("Role")}>
              <Select dropdownMatchSelectWidth={false}>
                {roles.map((role) => (
                  <Select.Option key={role.name} value={role.id}>
                    {role.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        }
      </Modal>
    </Spin>
  );
};

SettingsMembersTable.defaultProps = {
  users: [],
  roles: [],
};

SettingsMembersTable.propsType = {
  users: PropTypes.array,
  roles: PropTypes.array,
};

export default SettingsMembersTable;
