export default {
    when() {
        return $('[data-modal]').length > 0;
    },

    mounted() {
        $('[data-modal]').each(function () {
            const $this = $(this);
            const $modal = $('#modal-' + $this.data('modal'));

            if ($modal.length && !$modal.data('instance')) {
                $modal.data('instance', new ModalComponent($modal[0], $modal.data()));
            }
        });
    },
}

class ModalComponent {
    constructor(selector, options = {}) {
        this.element = selector.nodeName ? selector : document.querySelector(selector);
        this.options = {
            backdrop: 'close', // or 'static'
            backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
            onHide: () => {},
            onShow: () => {},
            onToggle: () => {},
            ...options,
        };
        this.backdrop = null;
        this.isVisible = false;

        document.querySelectorAll(`[data-modal="${this.element.id.replace('modal-', '')}"]`).forEach((element) => {
            element.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.toggle();
            });
        });
    }

    createBackdrop() {
        if (this.options.backdrop) {
            this.backdrop = document.createElement('div');
            this.backdrop.classList.add(...this.options.backdropClasses.split(' '));
            this.element.append(this.backdrop);

            if (this.options.backdrop === 'close') {
                this.backdrop.addEventListener('click', () => this.hide());
            }
        }
    }

    hide() {
        this.element.classList.add('hidden');
        this.element.classList.remove('flex');
        this.removeBackdrop();
        document.body.classList.remove('modal-open');
        this.isVisible = false;
        this.options.onHide();
    }

    removeBackdrop() {
        if (this.backdrop) {
            this.backdrop.remove();
            this.backdrop = null;
        }
    }

    show() {
        this.createBackdrop();
        this.element.classList.remove('hidden');
        this.element.classList.add('flex');
        document.body.classList.add('modal-open');
        this.isVisible = true;
        this.options.onShow();
    }

    toggle() {
        if (this.isVisible) {
            this.hide();
        } else {
            this.show();
        }

        this.options.onToggle();
    }
}
