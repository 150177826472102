/**
 * Format a Google Address components.
 * @param address_components
 * @param formats
 * @return {*}
 */
export function address(address_components, formats = {
    locality: 'city',
    route: 'street',
    postal_code: 'postcode',
    street_number: 'building_number',
}) {
    const ADDRESS_COMPONENTS = {
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'long_name',
        country: 'long_name',
        locality: 'long_name',
        postal_code: 'short_name',
        route: 'long_name',
        street_number: 'short_name',
        subpremise: 'short_name',
    };

    const returnData = address_components.reduce((acc, item) => {
        const addressType = item.types[0];

        if (ADDRESS_COMPONENTS[addressType] && formats[addressType]) {
            acc[formats[addressType]] = item[ADDRESS_COMPONENTS[addressType]];
        }

        return acc;
    }, {});


    // returnData.autocompleteText = document.getElementById(this.attributes.id).value;
    // returnData.latitude = place.geometry.location.lat();
    // returnData.longitude = place.geometry.location.lng();

    return returnData;
}

/**
 * Format given value as a currency using given options.
 * @param value
 * @param options
 * @return {string}
 */
export function currency(value, options = {}) {
    const {language, ...settings} = {
        currency: 'EUR',
        currencyDisplay: 'symbol',
        language: 'fr-FR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        style: 'currency',
        ...options,
    };
    // @see https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
    const formatter = new Intl.NumberFormat(language, settings);
    return formatter.format(value);
}
