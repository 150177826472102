import React, { useState, useEffect } from "react";
import i18next from "../../i18n";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Checkbox,
  Radio,
  Select,
} from "antd";
import InputArea from "./InputArea";

import { useTransactionTypes, useBuildingTypes, useUsers } from "./hooks";
import TextArea from "antd/lib/input/TextArea";

const FormSection = ({ title, children }) => (
  <div className="bg-white p-4 rounded-lg shadow mb-4">
    <div className="mb-4">
      <p className="text-lg leading-6 text-indigo-600 font-medium">{title}</p>
    </div>
    {children}
  </div>
);

const BuildingTypeGroup = ({ label, plainOptions, onChangeSet }) => {
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  useEffect(() => {
    onChangeSet(checkedList);
  }, [checkedList]);
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions.map((o) => o.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <div>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        {label}
      </Checkbox>
      <div className="pl-2">
        <Checkbox.Group
          className="grid grid-cols-1"
          options={plainOptions}
          value={checkedList}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const FiltersForm = ({ form, ...formProps }) => {
  const transactionTypes = useTransactionTypes();
  const buildingTypes = useBuildingTypes();

  const [selectedBuildingTypes, setSelectedBuildingTypes] = useState({});
  const onUpdateBuildingTypes = (list, group) => {
    let selection = {
      ...selectedBuildingTypes,
    };
    selection[group] = list;
    setSelectedBuildingTypes(selection);
    form.setFieldsValue({
      ...form.getFieldsValue(true),
      building_types: Object.values(selection).reduce(
        (a, b) => [...a, ...b],
        []
      ),
    });
  };
  const users = useUsers();

  return (
    <Form form={form} {...formProps}>
      <div className="flex flex-col w-full">
        <div className="overflow-y-auto mb-auto" style={{ flexBasis: "60vh" }}>
          <div>
            <FormSection
              title={i18next.t("filtersform.sections.transactions.title")}
            >
              <div className="mb-4">
                <p className="text-gray-700 mb-2 font-medium">
                  {i18next.t("places.fields.price")}
                </p>
                <div className="md:flex gap-4 border-b border-gray-200">
                  <Form.Item
                    name="price_min"
                    label={i18next.t("filtersform.between")}
                    className="flex-grow"
                  >
                    <InputNumber addonAfter="€" min={0} className="w-full" />
                  </Form.Item>
                  <Form.Item
                    name="price_max"
                    label={i18next.t("filtersform.and")}
                    className="flex-grow"
                  >
                    <InputNumber addonAfter="€" min={0} className="w-full" />
                  </Form.Item>
                </div>
              </div>
              <div className="mb-4">
                <p className="text-gray-700 mb-2 font-medium">
                  {i18next.t("places.fields.act_date")}
                </p>
                <div className="md:flex gap-4 border-b border-gray-200">
                  <Form.Item
                    name="act_date_min"
                    label={i18next.t("filtersform.from_date")}
                    className="flex-grow"
                  >
                    <Input type="date" className="form-input w-full" />
                  </Form.Item>
                  <Form.Item
                    name="act_date_max"
                    label={i18next.t("filtersform.to_date")}
                    className="flex-grow"
                  >
                    <Input type="date" className="w-full form-input" />
                  </Form.Item>
                </div>
              </div>
              <div>
                <p className="text-gray-700 mb-2 font-medium">
                  {i18next.t("places.fields.transaction_type")}
                </p>
                <Form.Item name="transaction_types">
                  <Checkbox.Group
                    className="grid  grid-cols-1 md:grid-cols-2"
                    options={transactionTypes}
                  />
                </Form.Item>
              </div>
            </FormSection>
            <FormSection
              title={i18next.t("filtersform.sections.building_type.title")}
            >
              <div className="md:flex gap-4">
                <div className="grid grid-cols-1 md:grid-cols-3">
                  {buildingTypes.map((group) => (
                    <BuildingTypeGroup
                      key={group.value}
                      plainOptions={group.items}
                      label={group.label}
                      onChangeSet={(list) => {
                        onUpdateBuildingTypes(list, group.value);
                      }}
                    />
                  ))}
                </div>
              </div>
            </FormSection>
            <FormSection
              title={i18next.t("filtersform.sections.description.title")}
            >
              <div className="mb-4 border-b border-gray-200">
                <Form.Item
                  name="residence"
                  label={i18next.t("places.fields.residence")}
                  className="flex-grow"
                >
                  <Input type="text" className="w-full form-input" />
                </Form.Item>
              </div>
              <div className="mb-4 border-b border-gray-200">
                <p className="text-gray-700 mb-2 font-medium">
                  {i18next.t("places.fields.cadastre")}
                </p>
                <Form.Item
                  name="cadastre_division"
                  label={i18next.t("places.fields.cadastre_division")}
                  className="flex-grow mb-2"
                >
                  <Input type="text" className="w-full form-input" />
                </Form.Item>
                <Form.Item
                  name="cadastre_section"
                  label={i18next.t("places.fields.cadastre_section")}
                  className="flex-grow mb-2"
                >
                  <Input type="text" className="w-full form-input" />
                </Form.Item>
                <Form.Item
                  name="cadastre_number"
                  label={i18next.t("places.fields.cadastre_number")}
                  className="flex-grow"
                >
                  <Input type="text" className="w-full form-input" />
                </Form.Item>
              </div>
              <div className="mb-4 border-b border-gray-200">
                <p className="text-gray-700 mb-2 font-medium">
                  {i18next.t("places.fields.rcni")}
                </p>
                <div className="md:flex gap-4">
                  <Form.Item
                    name="rcni_min"
                    label={i18next.t("filtersform.between")}
                    className="flex-grow mb-2"
                  >
                    <InputNumber
                      addonAfter="€"
                      min={0}
                      placeholder="min"
                      className="w-full"
                    />
                  </Form.Item>
                  <Form.Item
                    name="rcni_max"
                    label={i18next.t("filtersform.and")}
                    className="flex-grow"
                  >
                    <InputNumber
                      addonAfter="€"
                      min={0}
                      placeholder="min"
                      className="w-full"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="mb-4 border-b border-gray-200">
                <p className="text-gray-700 mb-2 font-medium">
                  {i18next.t("places.fields.price_m2_pnd")}
                </p>
                <Form.Item
                  name="price_m2_pnd_min"
                  label={i18next.t("filtersform.between")}
                  className="flex-grow mb-2"
                >
                  <InputNumber
                    addonAfter="€"
                    min={0}
                    placeholder="min"
                    className="w-full"
                  />
                </Form.Item>
                <Form.Item
                  name="price_m2_pnd_max"
                  label={i18next.t("filtersform.and")}
                  className="flex-grow"
                >
                  <InputNumber
                    addonAfter="€"
                    min={0}
                    placeholder="min"
                    className="w-full"
                  />
                </Form.Item>
              </div>
              <div className="mb-4 border-b border-gray-200">
                <p className="text-gray-700 mb-2 font-medium">
                  {i18next.t("places.fields.field_area")}
                </p>
                <Form.Item
                  name="field_area_min"
                  label={i18next.t("filtersform.between")}
                  className="flex-grow mb-2"
                >
                  <InputArea />
                </Form.Item>
                <Form.Item
                  name="field_area_max"
                  label={i18next.t("filtersform.and")}
                  className="flex-grow"
                >
                  <InputArea />
                </Form.Item>
              </div>
              <div className="mb-4 border-b border-gray-200">
                <p className="text-gray-700 mb-2 font-medium">
                  {i18next.t("places.fields.construction_year")}
                </p>
                <div className="flex gap-4">
                  <Form.Item
                    name="construction_year_min"
                    label={i18next.t("filtersform.between")}
                    className="flex-grow mb-2"
                  >
                    <InputNumber
                      min={1600}
                      step={1}
                      className="w-full flex-grow"
                    />
                  </Form.Item>
                  <Form.Item
                    name="construction_year_max"
                    label={i18next.t("filtersform.and")}
                    className="flex-grow"
                  >
                    <InputNumber
                      min={1600}
                      step={1}
                      className="w-full flex-grow"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="mb-4 border-b border-gray-200">
                <p className="text-gray-700 mb-2 font-medium">
                  {i18next.t("places.fields.room_number")}
                </p>
                <div className="flex gap-4">
                  <Form.Item
                    name="room_number_min"
                    label={i18next.t("filtersform.between")}
                    className="flex-grow mb-2"
                  >
                    <InputNumber
                      min={0}
                      step={1}
                      className="w-full flex-grow"
                    />
                  </Form.Item>
                  <Form.Item
                    name="room_number_max"
                    label={i18next.t("filtersform.and")}
                    className="flex-grow"
                  >
                    <InputNumber
                      min={0}
                      step={1}
                      className="w-full flex-grow"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="mb-4 border-b border-gray-200">
                <p className="text-gray-700 mb-2 font-medium">
                  {i18next.t("filtersform.sections.garages.title")}
                </p>
                <div className="flex gap-4">
                  <Form.Item
                    name="garages_internal"
                    label={i18next.t("filtersform.sections.garages.internal")}
                    className="flex-grow mb-2"
                  >
                    <InputNumber
                      min={0}
                      step={1}
                      placeholder={"min"}
                      className="w-full flex-grow"
                    />
                  </Form.Item>
                  <Form.Item
                    name="garages_external"
                    label={i18next.t("filtersform.sections.garages.external")}
                    className="flex-grow"
                  >
                    <InputNumber
                      min={0}
                      step={1}
                      placeholder={"min"}
                      className="w-full flex-grow"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="mb-4">
                <p className="text-gray-700 mb-2 font-medium">
                  {i18next.t("places.fields.garden")}
                </p>
                <Form.Item name="garden" className="w-full">
                  <Radio.Group>
                    <Radio value={1}>{i18next.t("places.fields.yes")}</Radio>
                    <Radio value={0}>{i18next.t("places.fields.no")}</Radio>
                    <Radio value={-1}>
                      {i18next.t("places.fields.unspecified")}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </FormSection>
            {!users.loading && users.users.length > 0 && (
              <FormSection title={i18next.t("places.fields.owner")}>
                <Form.Item
                  name="user_id"
                  label={i18next.t("places.fields.owner")}
                  className="w-full"
                >
                  <Select className="w-full">
                    <Select.Option value={null}></Select.Option>
                    {users.users.map((user) => (
                      <Select.Option key={user.id} value={user.id}>
                        {user.last_name} {user.first_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </FormSection>
            )}
            <FormSection title={i18next.t("places.fields.comments")}>
              <Form.Item
                name="comment"
                label={i18next.t("places.fields.comments")}
                className="w-full"
              >
                <TextArea className="w-full" />
              </Form.Item>
            </FormSection>
          </div>
        </div>
      </div>
    </Form>
  );
};
export default FiltersForm;
