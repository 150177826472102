import React from 'react';
import {Modal, Button, message} from 'antd'
import {useTranslation} from "react-i18next";
import axios from "axios";

const DeleteConfirmationModale = ({targetUrl, title, contentText, onActionComplete, onCancel, ...modalprops}) => {
    const [visible, setVisible] = React.useState(true)
    const [confirmLoading, setConfirmLoading] = React.useState(false)
    const [modalText, setModalText] = React.useState('Content')
    const {t} = useTranslation()
    const handleConfirm = () => {
        setConfirmLoading(true);
        axios.delete(targetUrl)
            .then(()=>{
                message.success('places.modals.delete.messages.success')
                setConfirmLoading(false)
                onActionComplete()
            })
            .catch(err => {
                if(error.response){
                    console.error(error.response)
                    message.error(t('places.modals.delete.messages.failure'))
                }else if(error.request){
                    console.error(error.request)
                    message.error(t('places.modals.delete.messages.failure'))
                }
                else {
                    message.error(err.message)
                }
            })
            .finally(()=>{
                setVisible(false)
            })
    }

    const handleCancel = () => {
        setVisible(false);
        onCancel()
    }

    return (
        <>
          <Modal
            title={title}
            visible={visible}
            onOk={handleConfirm}
            okType='danger'
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            destroyOnClose={true}
            {...modalprops}
          >
            <p>{contentText}</p>
          </Modal>
        </>
    )
}

export default DeleteConfirmationModale;
