export default {
    when() {
        return $('.js-tabs').length > 0;
    },

    mounted() {
        $('.js-tabs').each(function () {
            const $this = $(this);
            $this.data('instance', new TabsComponent(this, $this.data()));
        });
    },
};

class TabsComponent {
    constructor(selector, options = {}) {
        this.element = selector.nodeName ? selector : document.querySelector(selector);
        this.options = {
            active: null,
            activeClasses: 'opacity-100',
            activeTabClasses: 'border-indigo-500 text-indigo-600',
            inactiveClasses: 'opacity-0 hidden',
            inactiveTabClasses: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
            ...options,
        };
        this.select = this.element.querySelector('[name="active-tab"]');
        this.tabs = Array.from(this.element.querySelectorAll('[data-tab]'));
        this.panels = this.tabs.map((tab) => this.element.querySelector('#tab-' + tab.dataset.tab));

        this.tabs.forEach((tab) => {
            if (!this.options.active) {
                this.options.active = tab.dataset.tab;
            }

            tab.addEventListener('click', () => this.show(tab.dataset.tab));
        });

        this.select.addEventListener('change', (e) => {
            this.show(e.target.value);
        });

        if (this.options.active) {
            this.show(this.options.active);
        }
    }

    show(name) {
        this.tabs.forEach((tab) => {
            tab.classList.remove(...this.options.activeTabClasses.split(' '));
            tab.classList.add(...this.options.inactiveTabClasses.split(' '));

            if (tab.dataset.tab === name) {
                tab.classList.remove(...this.options.inactiveTabClasses.split(' '));
                tab.classList.add(...this.options.activeTabClasses.split(' '));
            }
        });

        this.panels.forEach((panel) => {
            panel.classList.remove(...this.options.activeClasses.split(' '));
            panel.classList.add(...this.options.inactiveClasses.split(' '));

            if (panel.id === 'tab-' + name) {
                panel.classList.remove(...this.options.inactiveClasses.split(' '));
                panel.classList.add(...this.options.activeClasses.split(' '));
            }
        });

        this.select.value = name;
    }
}
