import axios from "axios";
import React, { useState, useEffect } from "react";
import i18n from "../i18n";

const ExportPlacebutton = ({ placeId }) => {
  const [loading, setLoading] = useState(false);

  const toggleLoading = (value) => {
    setLoading(value);
  };
  const triggerExport = () => {
    if (loading) {
      return;
    }
    const data = {};
    data[placeId] = placeId;

    setLoading(true);
    axios({
      method: "post",
      url: "/places/export",
      responseType: "blob",
      data,
    }).then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const filename =
          response.headers["content-disposition"].split("filename=")?.[1] ??
          "export.xlsx";
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "export.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toggleLoading(false);
      },
      (error) => {
        toggleLoading(false);
        alert("Export failed");
      }
    );
  };
  return (
    <button className={`btn btn-light btn-rounded`} onClick={triggerExport}>
      {loading && "loading"}
      {!loading && (
        <>
          <i className="fa-regular fa-file-export" aria-hidden="true"></i>
          {i18n.t("general.export")}
        </>
      )}
    </button>
  );
};

export default ExportPlacebutton;
