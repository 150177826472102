import React, {useState, useEffect} from 'react'
import i18next from '../../i18n';

export const useUsers = () => {
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])
    useEffect(()=> {
        setLoading(true)
        window.axios.get('/api/users')
            .then(response => {
                const res = [...response.data.data].sort((a, b) => (`${a.last_name} ${a.first_name}`.toLowerCase() > `${b.last_name} ${b.first_name}`.toLowerCase())?1:-1 )
                setUsers(res)
            })
            .catch(err => {
                console.error('error while loading users', err);
                setUsers([])
            })
            .finally(()=>{
                setLoading(false)
            })
    }, [])
    return {
        loading,
        users
    }
}

export const useTransactionTypes = () => {
    return [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7
    ].map(t => ({label: i18next.t(`places.transactions.type.${t}`), value:t}))

}
export const useBuildingTypes = () => {
    const types = {
        'A' : [
            'A01',
            'A02',
            'A03',
            'A04',
            'A05',
            'A06',
            'A07',
            'A08',
            'A09',
            'A10',
            'A11',
            'A12',
            'A13',
            'A14',
            'A99',
        ],
        'B' : [
            'B01',
            'B02',
            'B03',
            'B04',
            'B05',
            'B06',
            'B07',
            'B08',
            'B09',
            'B99',
        ],
        'C':[
            'C01',
            'C02',
            'C03',
            'C04',
            'C05',
            'C06',
            'C07',
            'C08',
            'C09',
            'C99',
        ],
    };

    return Object.keys(types).map((group) => {
        const values = types[group]
        return {
            value: group,
            label: i18next.t(`building.type.${group}`),
            items: values.map(v => ({
                value: v,
                label: i18next.t(`building.type.${v}`)
            }))
        }
    });

}
