export default {
    when() {
        $(document).on('refresh', () => this.mounted());
        return $('.js-dropdown').length > 0;
    },

    mounted() {
        $('.js-dropdown:not(.initialized)').each(function () {
            const $this = $(this).addClass('initialized');
            const $handler = $this.find('[data-dropdown]');
            const $dropdown = $($handler.data('dropdown'));

            let timeout;

            $this.on('mouseleave', function () {
                if ($dropdown.css('visibility')) {
                    timeout = setTimeout(function () {
                        $dropdown.css('visibility', 'hidden');
                    }, 400);
                }
            });

            $this.add($dropdown).on('mouseenter', function () {
                clearTimeout(timeout);
            });

            $handler.on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                if (timeout) {
                    clearTimeout(timeout);
                }

                const $target = $($handler.data('dropdown'));
                const visibilityOptions = {
                    hidden: 'visible',
                    visible: 'hidden',
                };
                $target.css('visibility', visibilityOptions[ $target.css('visibility') ]);
            });
        });
    },
}
