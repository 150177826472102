import React, {useState} from 'react'
import {Col, InputNumber, Row, Slider} from 'antd';

const InputSlider = ({value, min, max, name, step, disabled}) => {
    const [inputValue, setInputValue] = useState(Number(value))
    const onChange = (value) => {
        if(isNaN(value)) {
            return
        }
        setInputValue(value)
    }

    return (
        <div className="flex flex-row gap-4">
          <InputNumber
            style={{width:60}}
            name={name}
            min={min}
            max={max}
            step={step}
            value={inputValue}
            onChange={onChange}
            disabled={disabled}
          />
          <Slider
            className="flex-1"
            min={Number(min)}
            max={Number(max)}
            step={Number(step)}
            value={typeof inputValue === 'number' ? inputValue : null}
            onChange={onChange}
            dots={true}
            trackStyle={{display:'None'}}
            disabled={disabled}
          />

        </div>
    )
}

export default InputSlider
