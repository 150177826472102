import shared from "../config/shared";

export default {
  when() {
    return $(".js-autocomplete").length > 0;
  },

  mounted() {
    $(".js-autocomplete").each(function () {
      const element = this;
      const $this = $(element);
      const options = {
        fields: ["place_id", "address_components", "geometry"],
        preventSubmit: false,
        types: ["address"],
        ...($this.data("options") || {}),
      };

      // force first item to be selected on enter
      const oldListener = this.addEventListener;

      element.addEventListener = (type, listener) => {
        // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
        // and then trigger the original listener.
        if (type === "keydown") {
          const previousListener = listener;

          listener = (event) => {
            const hasSelection =
              document.querySelectorAll(".pac-item-selected").length > 0;

            if (event.which === 13 && !hasSelection) {
              const simulatedDownArrow = new KeyboardEvent("keydown", {
                keyCode: 40,
                which: 40,
              });
              previousListener.apply(element, [simulatedDownArrow]);
            }

            if (event.which === 13 && options.preventSubmit) {
              event.preventDefault();
            }

            previousListener.apply(element, [event]);
          };
        }

        oldListener.apply(element, [type, listener]);
      };

      // initialize autocomplete
      const autocomplete = new google.maps.places.Autocomplete(
        element,
        options
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          return;
        }

        if (place.address_components !== undefined) {
          shared.get("dispatcher").dispatch("change.js-autocomplete", place);
        }
      });
      shared.get("dispatcher").listen("clear.js-autocomplete", () => {
        console.log("clear auto complete", autocomplete);
        element.value = "";
      });
    });
  },
};
