import PermissionsTable from "../../components/settings/SettingsPermissionsTable";
import React, { createRoot } from "react-dom/client";

export default {
  when: "settings-permissions",
  mounted: () => {
    const Container = document.querySelector("#settings_permissions_table");
    createRoot(Container).render(<PermissionsTable />);
  },
};
